import { ArrowRightOutlined, CloseOutlined, FileDoneOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Modal, Row, Space, Tooltip, Select, Form, Input, Tabs, Table } from "antd";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PageHeader from "../../view/pageheader";
import SelectInput from "../CustomComponents/SelectInput";
import TableComponent from "../CustomComponents/TableComponent";
import TextInput from "../CustomComponents/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { getJobsList } from "../../store/reducer/jobSlice";
import { useNavigate } from "react-router-dom";
import {
   addPrepTeamQueue,
   getPrepTeamQueue,
   updatePrepTeamQueue,
   resetSuccessFlag,
   prepTeamQueueUsersData,
   addDataAllocation,
   prepDataAllocationById

} from "../../store/reducer/prepQueueSlice";
import { getUserManagementList } from "../../store/reducer/userManagementSlice";
import { toast } from "react-toastify";
import SearchComponent from "../CustomComponents/SearchComponent";
import { getCustomerList } from "../../store/reducer/customerSlice";
const AllocationModal = ({ record, totalVolume = record?.input_number, userData, onCancel }) => {
   const dispatch = useDispatch();
   const { allocatePrepQueueByIdData, addDataAllocationSuccess } = useSelector((state) => state.prepTeamQueue);
   const [form] = Form.useForm();
   const [allocated, setAllocated] = useState(0);
   const [isModalVisible, setIsModalVisible] = useState(true);
   const [isPrevAllocatedModalVisible, setIsPrevAllocatedModalVisible] = useState(false); // New state for previous allocation modal
   const [originalAllocations, setOriginalAllocations] = useState([]);
   const [prevAllocations, setPrevAllocations] = useState([]); // New state for previous allocations
   const userId = localStorage.getItem('user_id');

   function getData(payload = {}) {
      if (userId == 1) {
         dispatch(getPrepTeamQueue(payload));
      } else {
         dispatch(prepTeamQueueUsersData({ userId }));
      }
   }

   useEffect(() => {
      getData();
      if (record?.id) {
         dispatch(prepDataAllocationById({ editId: record.id }));
      }
   }, [dispatch, record]);

   useEffect(() => {
      console.log("Raw Data from API:", allocatePrepQueueByIdData); // Debug API response

      if (allocatePrepQueueByIdData && allocatePrepQueueByIdData.length > 0 && record?.id) {
         const filteredData = allocatePrepQueueByIdData
            .filter(item => item.prep_queue_id === record.id)
            .map((item) => ({
               user: { label: item?.prep_users?.user_name, value: item.assign_user_id },
               data_count: item.data_count,
               timestamp: item.created_at,
            }));

         // Debug formatted data

         // form.setFieldsValue({ allocations: filteredData });
         setOriginalAllocations(filteredData);
         setPrevAllocations(filteredData);

         const totalAllocated = filteredData.reduce((sum, allocation) => sum + (parseInt(allocation.data_count, 10) || 0), 0);
         setAllocated(totalAllocated);
      }
   }, [allocatePrepQueueByIdData, record, form]);

   const handleFormChange = (_, allValues) => {
      const allocations = allValues.allocations || [];
      const totalAllocated = allocations.reduce((sum, allocation) => sum + (parseInt(allocation?.data_count, 10) || 0), 0);
      // setAllocated(totalAllocated);
   };

   const handleOk = () => {
      form.validateFields().then((values) => {
         const totalAssigned = values.allocations.reduce((sum, { data_count }) => sum + (parseInt(data_count, 10) || 0), 0);

         if (totalAssigned > totalVolume) {
            toast.error("Total allocation exceeds available boxes!");
            return;
         }

         const newOrUpdatedAllocations = values.allocations.filter((allocation) => {
            const existingAllocation = originalAllocations.find(
               (orig) => orig.user.value === allocation.user.value
            );
            return (
               !existingAllocation ||
               existingAllocation.data_count !== allocation.data_count
            );
         });

         if (newOrUpdatedAllocations.length === 0) {
            toast.info("No changes detected in allocations.");
            return;
         }

         const payload = newOrUpdatedAllocations.map((allocation) => ({
            assign_user_id: allocation.user.value,
            prep_queue_id: record?.id,
            data_count: allocation?.data_count,
            customer_id: record?.customer_id
         }));

         dispatch(addDataAllocation({ postData: payload }));

         toast.success("Allocation updated successfully");
         form.resetFields();
         onCancel();
      }).catch(() => {
         // toast.error("Please fix the errors before submitting.");
      });
   };

   useEffect(() => {
      if (addDataAllocationSuccess) {
         dispatch(prepTeamQueueUsersData({ userId }));
      }
   }, [dispatch, addDataAllocationSuccess, userId]);

   const handleCancel = () => {
      setIsModalVisible(false);
      onCancel();
   };

   const remainingBalance = totalVolume - allocated;
   console.log(remainingBalance, "remainingBalance1");



   return (
      <>
         {/* Allocation Modal */}
         <Modal
            title={`Total Boxes : ${allocated} / ${totalVolume} `}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose
         >
            <Button type="primary" onClick={() => setIsPrevAllocatedModalVisible(true)} style={{ marginBottom: 16 }}>
            Allocation History
            </Button>

            <Form form={form} layout="vertical" onValuesChange={handleFormChange}>
               <Form.List name="allocations">
                  {(fields, { add, remove }) => (
                     <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                           <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                              <Form.Item
                                 {...restField}
                                 name={[name, "user"]}
                                 fieldKey={[fieldKey, "user"]}
                                 rules={[{ required: true, message: "Please select a user!" }]}
                              >
                                 <Select
                                    placeholder="Select a user"
                                    style={{ width: 150 }}
                                    labelInValue
                                    options={userData}
                                 />
                              </Form.Item>

                              <Form.Item
                                 {...restField}
                                 name={[name, "data_count"]}
                                 fieldKey={[fieldKey, "data_count"]}
                                 rules={[
                                    { required: true, message: "Enter a valid number" },
                                    ({ getFieldValue }) => ({
                                       validator(_, value) {
                                          if (!value || isNaN(value)) {
                                             return Promise.reject(new Error("Please enter a valid number."));
                                          }



                                          const currentTotal = (getFieldValue(["allocations"]) || []).reduce(


                                             (sum, allocation) => sum + (parseInt(allocation?.data_count) || 0),
                                             0,);

                                          if (currentTotal > remainingBalance) {
                                             return Promise.reject(new Error("Total allocation exceeds available boxes!"));
                                          }
                                          return Promise.resolve();
                                       },
                                    }),
                                 ]}
                              >
                                 <Input type="number" placeholder="Min" style={{ width: 80 }} />
                              </Form.Item>

                              <MinusCircleOutlined onClick={() => remove(name)} />
                           </Space>
                        ))}

                        <Form.Item>
                           <Button
                              type="dashed"
                              onClick={() => {
                                 if (allocated < totalVolume) add();
                                 else toast.error("Total allocation limit reached!");
                              }}
                              block
                              icon={<PlusOutlined />}
                           >
                              Add User
                           </Button>
                        </Form.Item>
                     </>
                  )}
               </Form.List>
            </Form>
         </Modal>

         {/* Previously Allocated Users Modal */}
         <Modal
            title="Previously Allocated Users"
            visible={isPrevAllocatedModalVisible}
            onCancel={() => setIsPrevAllocatedModalVisible(false)}
            footer={null}
            width={1000}
         >
            <Table
               dataSource={prevAllocations}
               columns={[
                  {
                     title: "User",
                     dataIndex: "user",
                     key: "user",
                     render: (user) => user.label,
                  },
                  {
                     title: "Allocated Boxes",
                     dataIndex: "data_count",
                     key: "data_count",
                  },
                  {
                     title: "Timestamp",
                     dataIndex: "timestamp",
                     key: "timestamp",
                     render: (timestamp) => {
                        const [date, time] = timestamp.split('T'); // Splitting date and time
                        const formattedTime = time.split('.')[0]; // Removing milliseconds
                        return `${date} ${formattedTime}`; // Output: "2025-03-20 07:43:28"
                     },
                  },
               ]}
               rowKey={(record) => record.user.value}
            />
         </Modal>
      </>
   );
};



const PrepTeamQueue = () => {
   const [PrepTeamQueueSchema] = useState({
      customer_id: null,
      input_number: "",
   });
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [type, setType] = useState('add')
   const [editId, setEditId] = useState(null);
   const [managerData, setManagerData] = useState([]);
   const [customerList, setCustomerList] = useState([]);
   const [jobData, setJobData] = useState([]);
   const userId = localStorage.getItem('user_id');
   const role_id = localStorage.getItem("role_id");
   const {
      prepTeamQueueListData,
      addPrepTeamQueueSuccess,
      updatePrepTeamQueueSuccess,
      prepTeamQueueUsersListData
   } = useSelector((state) => state.prepTeamQueue);
   const { userManagementListData, } = useSelector((state) => state.userManagement);
   const { customerListData } = useSelector((state) => state.customers);
   const { jobListData } = useSelector((state) => state.jobs);
   const { completedRecords: totalCount, data: tableData } = role_id == 1 ? prepTeamQueueListData : prepTeamQueueUsersListData;
   console.log(tableData, "tableData");


   function getDataProjectManager(payload = {}) {
      dispatch(getUserManagementList(payload));
      dispatch(getCustomerList(payload))
      dispatch(getJobsList(payload));
   }
   useEffect(() => {
      getDataProjectManager();
   }, []);
   useEffect(() => {
      if (userManagementListData?.data) {
         setManagerData(userManagementListData?.data);
      }
      if (customerListData?.data) {
         setCustomerList(customerListData?.data);
      }
      if (jobListData?.data) {
         setJobData(jobListData?.data);
      }
   }, [userManagementListData, customerListData, jobListData]);

   function getData(payload = {}) {
      if (userId == 1) {
         dispatch(getPrepTeamQueue(payload));
      } else {
         dispatch(prepTeamQueueUsersData({ userId }));
      }

   }
   const filteredUsers = managerData.filter(item => item.role_id == 3 && item.status == 1)
      .filter(user => user.user_groups.some(group => group.group_id == 1)) // Filter users with group_id 4
      .map(user => ({
         label: user.user_name,
         value: user.id,
      }));
   const customerLists = useMemo(() => {
      return customerList.map((item) => ({
         label: item.customername,
         value: item.id
      }));
   }, [customerList])
   const validationRules = yup.object().shape({
      customer_id: yup.number().required("Customer name is required"),
      input_number: yup
         .number()
         .typeError("Enter a valid number")
         .positive("Number must be greater than zero")
         .integer("Number must be a whole number")
         .required("Enter Number"),

   });

   const {
      control,
      handleSubmit,
      formState: { errors },
      watch,
      reset,
   } = useForm({
      resolver: yupResolver(validationRules),
      mode: "onChange", // Enable live validation
   });
   const inputNumberValue = watch("input_number");
   const customerIdValue = watch("customer_id");
   const [isAddModal, setIsAddModal] = useState(false);
   const [isInputModal, setIsInputModal] = useState(false);
   const [selectedRecord, setSelectedRecord] = useState(null);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const inputAutoFocusRef = useRef(null);
   const specIdData = useMemo(() => {
      return jobData
         ?.filter((item) => item?.customer_id == customerIdValue) // Filter data
         .map((item) => ({ label: item?.spec_id, value: item?.id })); // Map to required format
   }, [jobData, customerIdValue]); // Include dependencies correctly


   useEffect(() => {
      getData();
      if (addPrepTeamQueueSuccess) {
         toast.success("Customer added successfully");
         setIsAddModal(false); // Close the modal
         reset({ ...PrepTeamQueueSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "addPrepTeamQueueSuccess" })); // Reset the success flag
      }
      if (updatePrepTeamQueueSuccess) {
         toast.success("Customer updated successfully");
         setIsAddModal(false); // Close the modal
         reset({ ...PrepTeamQueueSchema }); // Reset the form to the default schema
         dispatch(resetSuccessFlag({ flag: "updatePrepTeamQueueSuccess" })); // Reset the success flag
      }
   }, [addPrepTeamQueueSuccess, updatePrepTeamQueueSuccess, dispatch, reset, PrepTeamQueueSchema]);

   useEffect(() => {
      if (isAddModal && inputAutoFocusRef.current) {
         setTimeout(() => {
            inputAutoFocusRef.current.focus();
         }, 300);
      }
   }, [isAddModal]);

   const handleAllocate = (record) => {
      console.log(record, "setSelectedRecord11");

      setSelectedRecord(record);
      setIsModalOpen(true);
   };
   const handleCloseModal = () => {
      setIsModalOpen(false);
      setSelectedRecord(null);
   };
   const columns = [
      {
         title: "No of Boxes",
         dataIndex: "input_number", // Ensure the correct key
         key: "customername",
         align: "center",
      },
      {
         title: "Customer Name",
         dataIndex: "prep_customers", // Ensure the correct key
         key: "customername",
         align: "center",
         render: (prep_customers) => prep_customers?.customername || "No Name",
         sorter: (a, b) => a.prep_customers?.customername?.localeCompare(b.prep_customers?.customername),
      },

      {
         title: "Allocate to User",
         key: "allocate",
         align: "center",
         render: (_, record) => (
            <Button type="primary" className="primary-btn " onClick={() => handleAllocate(record)}>
               Allocate
            </Button>
         ),
      },


   ];


   const userColumns = [
      {
         title: "No of Boxes",
         dataIndex: "total_data_count", // Ensure the correct key
         key: "total_data_count",
         align: "center",
      },
      {
         title: "Customer Name",
         dataIndex: "prep_allocation_customers", // Ensure the correct key
         key: "customername",
         align: "center",
         render: (prep_allocation_customers) => prep_allocation_customers?.customername || "No Name",
         sorter: (a, b) => a.prep_allocation_customers?.customername?.localeCompare(b.prep_allocation_customers?.customername),
      },


      {
         title: "Completed Data",
         key: "total_data_count",
         align: "center",
         render: (text, record) => {
            let completedData = record.total_completed_data;
            
            if (completedData > record.total_data_count) {
              completedData = record.total_data_count;
            }
        
            return `${completedData}/${record.total_data_count}`;
          }
      },
      {
         title: "Assign",
         key: "Assign",
         align: "center",
         render: data => (
            <Space>
               <Tooltip title="Assign">
                  <Button
                     shape="circle"
                     className="border-20"
                     icon={<ArrowRightOutlined style={{ color: "blue" }} />}
                     onClick={() => navigate(`/add-preprocess/${btoa(data?.prep_queue_id)}`)}
                  />
               </Tooltip>
            </Space>
         ),
      },
   ];

   const onSubmit = (data) => {
      console.log(data, "kkk");

      if (type === "add") {
         const payload = {
            postData: data,
         };
         dispatch(addPrepTeamQueue(payload)); // Dispatch add action
      } else if (type == "edit") {
         const payload = {
            postData: {
               customer_id: data?.customer_id, // Extract the specific fields for update
               input_number: data?.input_number,
            },
            updateID: editId, // Use the editId for identifying the record to update
         };
         dispatch(updatePrepTeamQueue(payload)); // Dispatch update action
      }
   };

   const onModalClose = () => {
      reset();
      setIsAddModal(false);
   };
   const onModalOpen = () => {
      reset({ ...PrepTeamQueueSchema });
      setIsAddModal(true);
   };
   const onInputModalClose = () => {
      reset();
      setIsInputModal(false);
   };
   return (
      <div>
         <PageHeader HeaderText="Pre Processing Queue" Breadcrumb={[{ name: "Pre Processing Queue" }]} backButtonUrl="" />
         <Card
            title="Pre Processing Queue"
            extra={
               <>
                  <Space size={"middle"} className="my-3">
                     <SearchComponent

                        placeHolder="Search..."
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }} />
                  </Space>
                  {role_id != 3 && <>
                     <Button className="primary-btn ml-2" shape="round" onClick={() => {
                        setType("add");
                        onModalOpen(); // Open modal for Add
                     }}>
                        Add
                     </Button>
                  </>}
                  <Modal
                     title={type === "add" ? " Add Preprocess" : "Edit  Preprocess"}
                     centered
                     className="w-50"
                     open={isAddModal}
                     onOk={handleSubmit(onSubmit)}
                     onCancel={onModalClose}
                     okButtonProps={{
                        className: "primary-btn",
                        shape: "round",
                        icon: <FileDoneOutlined />,
                     }}
                     okText={type === "add" ? "Save" : "Update"}
                     cancelButtonProps={{ shape: "round", icon: <CloseOutlined /> }}
                  >
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={[10, 0]} wrap className="p-2">
                           <Col span={12}>
                              <TextInput
                                 ref={inputAutoFocusRef}
                                 name="input_number"
                                 control={control}
                                 label="No of Boxes"
                                 placeholder="Enter Box Number"
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           <Col span={12}>
                              <SelectInput
                                 name="customer_id"
                                 control={control}
                                 label="Customer Name"
                                 placeholder="Enter Customer name"
                                 options={customerLists}
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           {/* <Col span={12}>
                              <SelectInput
                                 name="assign_user_id"
                                 control={control}
                                 label="User Name"
                                 placeholder="Select User"
                                 options={filteredUsers}
                                 errors={errors}
                                 required={true}
                              />
                           </Col> */}

                        </Row>
                     </form>
                  </Modal>
                  <Modal
                     title={"Assign"}
                     centered
                     className="w-50"
                     open={isInputModal}
                     onOk={handleSubmit(onSubmit)}
                     onCancel={onInputModalClose}
                     okButtonProps={{
                        className: "primary-btn",
                        shape: "round",
                        icon: <FileDoneOutlined />,
                     }}
                     okText={type === "add" ? "Save" : "Update"}
                     cancelButtonProps={{ shape: "round", icon: <CloseOutlined /> }}
                  >
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={[10, 0]} wrap className="p-2">
                           {Array.from({ length: inputNumberValue }, (_, i) => (
                              <React.Fragment key={i}>
                                 <Col span={12}>
                                    <SelectInput
                                       ref={inputAutoFocusRef}
                                       name={`input_number_${i}`} // Unique name for each input field
                                       control={control}
                                       label={`Box Number ${i + 1}`}
                                       placeholder="Enter Box Number"
                                       options={specIdData}
                                       errors={errors}
                                       required={true}
                                    />
                                 </Col>
                                 <Col span={12}>
                                    <SelectInput
                                       name={`customer_id`} // Unique name for each select field
                                       control={control}
                                       label={`Customer Name `}
                                       placeholder="Enter Customer name"
                                       options={customerLists}
                                       errors={errors}
                                       disabled={true}
                                    />
                                 </Col>
                              </React.Fragment>
                           ))}
                        </Row>
                     </form>
                  </Modal>
               </>
            }
         >
            <TableComponent columns={userId == 1 ? columns : userColumns} dataSource={tableData} size={"small"} />
            {isModalOpen && selectedRecord && (
               <AllocationModal
                  record={selectedRecord}
                  totalVolume={selectedRecord?.input_number}
                  userData={filteredUsers}
                  onCancel={handleCloseModal}
               />
            )}
         </Card>
      </div>
   );
};

export default PrepTeamQueue;
