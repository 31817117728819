import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/scantek_logo.png";
import { useNavigate, useParams } from "react-router-dom";
import {
    resetPwd,
    loginSelector,
} from "../store/reducer/login";
import { API_STATUS } from "../utils/constants";

function ResetPassword() {
    const params = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [loginDetails, setLoginDetails] = useState({
        token: params?.token,
        new_password: "",
        confirm_password: "",
    });

    const [errors, setErrors] = useState({ new_password: "", confirm_password: "" }); // Track errors for each field

    const { resetloading, errorMessage } = useSelector(loginSelector);

    const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{12,}$/;

    const validateForm = () => {
        let tempErrors = { new_password: "", confirm_password: "" };
        let valid = true;

        if (!loginDetails.new_password) {
            tempErrors.new_password = "Please enter a new password";
            valid = false;
        } else if (!passwordValidationRegex.test(loginDetails.new_password)) {
            tempErrors.new_password = "Password should be alphanumeric, case-sensitive, and at least 12 characters";
            valid = false;
        }

        if (!loginDetails.confirm_password) {
            tempErrors.confirm_password = "Please confirm your password";
            valid = false;
        } else if (loginDetails.new_password !== loginDetails.confirm_password) {
            tempErrors.confirm_password = "Passwords do not match";
            valid = false;
        }

        setErrors(tempErrors);
        return valid;
    };

    const onLoginSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            dispatch(resetPwd({ postData: loginDetails }));
        }
    };

    useEffect(() => {
        if (resetloading === API_STATUS.FULFILLED) {
            navigate('/login');
        }
    }, [resetloading, navigate]);

    return (
        <div className="theme-cyan">
            <div className="vertical-align-wrap">
                <div className="vertical-align-middle auth-main">
                    <div className="auth-box">
                        <div className="top">
                            <img src={Logo} alt="Logo" style={{ height: "55px", width: "auto", margin: "10px" }} />
                        </div>
                        <div className="card">
                            <div className="header">
                                <p className="lead">Recover my password</p>
                            </div>
                            <div className="body">
                                <p>Please enter your password and confirm password for resetting password.</p>
                                <form onSubmit={onLoginSubmit} className="form-auth-small">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            placeholder="New Password"
                                            type="password"
                                            value={loginDetails.new_password}
                                            onChange={(e) =>
                                                setLoginDetails({ ...loginDetails, new_password: e.target.value })
                                            }
                                        />
                                        {errors.new_password && <span className="text-danger">{errors.new_password}</span>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            type="password"
                                            value={loginDetails.confirm_password}
                                            onChange={(e) =>
                                                setLoginDetails({ ...loginDetails, confirm_password: e.target.value })
                                            }
                                        />
                                        {errors.confirm_password && <span className="text-danger">{errors.confirm_password}</span>}
                                    </div>
                                    <button className="btn btn-primary btn-lg btn-block" type="submit">
                                        RESET PASSWORD
                                    </button>
                                    <div className="bottom">
                                        <span className="helper-text">
                                            Know your password? <a href={`${process.env.PUBLIC_URL}/`}>Login</a>
                                        </span>
                                    </div>
                                </form>
                                {resetloading === API_STATUS.REJECTED && errorMessage?.message && (
                                    <div className="text-danger mt-2">{errorMessage.message}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
