import React, { useEffect } from 'react'
import TableComponent from "../CustomComponents/TableComponent";
import PageHeader from '../../view/pageheader';
import { Card } from 'antd';
import { excelUploadData } from '../../store/reducer/prepQueueSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NODELOCALASSETURL } from '../../services/config'
const PreprocessingUploadLogs = () => {
    const dispatch = useDispatch();
    const { excelUploadDatas } = useSelector((state) => state.prepTeamQueue);
    const { data: tableData } = excelUploadDatas || {};
    console.log(tableData,"tableData1111");
    
    const userId = localStorage.getItem("user_id");
    function getData(payload = {}) {
        dispatch(excelUploadData({ ...payload, userId }));
    }
    useEffect(() => {
        getData({});
    }, [dispatch]);

    const columns = [
        {
            title: "File Name",
            dataIndex: "file_name",
            key: "file_name",
            align: "center",
            render: (text, record) => (
                <Link to={`${NODELOCALASSETURL}${record.file_path}`} target="_blank" rel="noopener noreferrer">
                  {text}
                </Link>
              ),
        },
        {
            title: "Total Records",
            dataIndex: "total_records",
            key: "total_records",
            align: "center",
            sorter: true,
      
          },
        {
            title:'Accepted Records',
            dataIndex: "accepted_records",
            key: "accepted_records",
            align: "center",
            // sorter: (a, b) => a.customername.localeCompare(b.customername),
        },
        {
            title: "Rejected Records",
            dataIndex: "rejected_records",
            key: "failed_records",
            align: "center",
            // sorter: (a, b) => a.customername.localeCompare(b.customername),
        }




    ];

    return (
        <>
            <PageHeader HeaderText="Preprocessing Upload Logs" backButtonUrl="" />
            <Card>
                <TableComponent columns={columns} dataSource={tableData} size={"small"} />
            </Card>
        </>
    );
};

export default PreprocessingUploadLogs