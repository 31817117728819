import React, { useState } from "react";
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

function PageHeader(Props) {
   const { HeaderText, Breadcrumb, backButtonUrl, buttonName } = Props;
   const [toggleMenu, setToggleMenu] = useState(true);
   const navigate = useNavigate();
   const location = useLocation();


   const onToggleMenu = () => {
      setToggleMenu(!toggleMenu);
      if (!toggleMenu) {
         document.body.classList.remove("layout-fullwidth");
      } else {
         document.body.classList.add("layout-fullwidth");
      }
   };
   return (
      <div className="block-header" style={{width: "99%"}}>
         <div className="row">
            <div className={location.pathname.includes('/rooms/') ? '' : 'col-lg-5 col-md-6 col-sm-12'}>
               <h6>
                  <a
                     href="#!"
                     className="btn btn-xs btn-link btn-toggle-fullwidth"
                     onClick={e => {
                        e.preventDefault();
                        onToggleMenu();
                     }}
                  >
                     {/* <i className={toggleMenu ? `fa fa-arrow-left` : "fa fa-arrow-right"}></i> */}
                     <MenuOutlined />
                  </a>{" "}
                  {HeaderText}
               </h6>
               {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                     <a href="/dashboard">
                        <i className="icon-home"></i>
                     </a>
                  </li>
                  {Breadcrumb.map((item, index) => {
                     return (
                        <li key={item.name + index} className="breadcrumb-item active">
                           <a href={item.navigate ? item.navigate : null}>{item.name}</a>
                        </li>
                     );
                  })}
               </ul> */}
            </div>
            <div className="col-lg-7 col-md-4 col-sm-12 text-right">
               {backButtonUrl && (
                  <Button
                     shape="round"
                     // size="large"
                     className="me-1 border-0"
                     icon={<i className="fa fa-arrow-circle-left fa-lg" aria-hidden="true" />}
                     // icon={<i className="fa fa-arrow-left fa-lg" aria-hidden="true" />}
                     style={{ backgroundColor: "#6E6E6E", color: "white" }}
                     onClick={() => navigate(backButtonUrl)}
                  >
                     {buttonName ? buttonName : "Back"}
                  </Button>
               )}
            </div>
         </div>
      </div>
   );
}
export default PageHeader;
