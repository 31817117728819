import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Flex, Form, Input, InputNumber, Select, Table, Modal, Upload } from 'antd';
import PageHeader from '../../view/pageheader';
import { DownloadOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { prepQueueById, editPreprocessingData, addExcelPrepProcess, updatePrepProcessData, resetSuccessFlag } from "../../store/reducer/prepQueueSlice";
import { getJobsList } from "../../store/reducer/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify'
import TableComponent from '../CustomComponents/TableComponent';

const App = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [preprocessingQueueData, setPreprocessingQueueData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const { prepQueueByIdData, addExcelPrepProcessSuccess, addExcelPrepProcessError, addExcelPrepProcessErrorMessage } = useSelector((state) => state.prepTeamQueue);
  const { jobListData } = useSelector((state) => state.jobs);
  const userId = localStorage.getItem('user_id');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [modifiedRows, setModifiedRows] = useState(new Set());

  function getAllData(payload = {}) {

    dispatch(getJobsList(payload))

  }

  useEffect(() => {
    if (id) {
      getAllData()
      const editId = atob(id);
      const assign_user_id = localStorage.getItem('user_id');
      dispatch(prepQueueById({ editId, assign_user_id }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (prepQueueByIdData) {
      setPreprocessingQueueData(prepQueueByIdData.map((item, index) => ({ ...item, key: index.toString() })));
    }
    if (prepQueueByIdData) {
      const formattedData = prepQueueByIdData.map((item, index) => ({
        ...item,
        key: index.toString(),
      }));
      setPreprocessingQueueData(formattedData);
      console.log(formattedData, "formattedData1111");

      setOriginalData(JSON.stringify(formattedData)); // Store original data as string for comparison
    }
    if (jobListData?.data) {
      setJobData(jobListData?.data);
    }
  }, [prepQueueByIdData]);


  const specIdData = useMemo(() => {
    if (!preprocessingQueueData.length || !jobData.length) return [];
    return jobData
      .filter((item) => item?.customer_id == preprocessingQueueData[0]?.customer_id).filter((item) => (item?.allocated == 1))
      .map((item) => ({ label: item?.spec_id, value: item?.id }));
  }, [jobData, preprocessingQueueData]);
  console.log(specIdData, "specIdData111");





  const handleDownloadExcel = () => {
    const tableData = preprocessingQueueData.map(({ id, customer_box_id, spec }) => ({
      'Unique ID': id,
      'Customer Box ID': customer_box_id,
      'Spec ID': spec?Number(spec):null,
    }));

    const specSheetData = [['Allowed Spec Id'], ...specIdData.map((item) => [Number(item.label)])]
  
    const ws1 = XLSX.utils.json_to_sheet(tableData);
    const ws2 = XLSX.utils.aoa_to_sheet(specSheetData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'Preprocessing Queue');
    XLSX.utils.book_append_sheet(wb, ws2, 'Allowed Spec IDs');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'Preprocessing_Queue.xlsx');
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileList([]);
  };

  const handleUpload = async () => {
    const allowedExtensions = ["xlsx", "xls", "xlsm", "xltx", "ods", "xlsb"];
    if (!fileList.length) {
      toast.error("Please select an Excel file to upload!");
      return;
    }

    const file = fileList[0];


    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Invalid file type! Please upload a valid Excel file.");
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet)

      // const allowedSpecIds = specIdData.map((item) => item.label);
      let hasErrors = false;
      const allSpecIdsEmpty = jsonData.every(row => row["Spec ID"] === null || row["Spec ID"] === undefined);
      if (allSpecIdsEmpty) {
        toast.error("All spec IDs is Empty");
        hasErrors = true;
      }
      if (jsonData.length > preprocessingQueueData.length) {
        toast.error("You have uploaded more records than allowed!");
        hasErrors = true;
      }

      jsonData.forEach((row, index) => {
        if (!row["Unique ID"]) {
          toast.error(`Row ${index + 1}: Missing required fields.`);
          hasErrors = true;
        }
      });

      jsonData.includes(jsonData)

      if (!hasErrors) {
        const postData = {
          jsonData,
          customer_id: preprocessingQueueData[0]?.customer_id,
          assign_user_id: userId,
          prep_queue_id: atob(id)
        }

        dispatch(addExcelPrepProcess({ postData }))
        setUploadModalVisible(true);

      }
    };

    reader.readAsArrayBuffer(file);
  };
  useEffect(() => {
    if (id) {
      try {
        const editId = atob(id);
        const assign_user_id = localStorage.getItem("user_id");

        if (assign_user_id) {
          dispatch(prepQueueById({ editId, assign_user_id }));
          // toast.success("Excel Prep Process Success");
          setIsModalOpen(false);
        } else {
          console.error("User ID not found in localStorage");
        }
      } catch (error) {
        console.error("Invalid base64 ID:", error);
      }
    }

    // if (addExcelPrepProcessError) {
    //   toast.error(addExcelPrepProcessErrorMessage || "An error occurred.");
    //   dispatch(resetSuccessFlag({ flag: "addExcelPrepProcessError" }));
    // }
  }, [dispatch, id, setIsModalOpen]);
  // const disabledData=preprocessingQueueData.filter((data)=>data.allocated==1)



  const columns = [
    {
      title: 'Unique ID',
      dataIndex: 'id',
      key: 'id',
      width: '25%',
    },
    {
      title: 'Customer Box ID',
      dataIndex: 'customer_box_id',
      key: 'customer_box_id',
      width: '35%',
      render: (text, record) => (
        <InputNumber style={{ width: '200px' }}
          value={record.customer_box_id}
          placeholder='Enter Box ID'
          disabled={record.allocated == 1}
          onChange={(value) => {
            const newData = [...preprocessingQueueData];
            const index = newData.findIndex((item) => item.key == record.key);
            if (index > -1) {
              newData[index] = { ...record, customer_box_id: value };
              setPreprocessingQueueData(newData);
              setModifiedRows(prev => new Set(prev.add(record.key)));
            }
          }}
        />
      ),
    },
    {
      title: 'Spec ID',
      dataIndex: 'spec',
      key: 'spec',
      width: '40%',
      render: (text, record) => (
        <Select
          style={{ width: '100%' }}
          options={specIdData}
          value={record.spec}
          placeholder='Select Spec ID'
          disabled={record.allocated == 1}
          onChange={(value) => {
            const selectedSpec = specIdData.find((item) => item.value == value);
            const newData = [...preprocessingQueueData];
            const index = newData.findIndex((item) => item.key === record.key);
            if (index > -1) {
              newData[index] = { ...record, spec: selectedSpec.label };
              setPreprocessingQueueData(newData);
              setModifiedRows(prev => new Set(prev.add(record.key)));
            }
          }}
        />
      ),
    },
  ];
  const handleSaveData = () => {

    if (originalData.length == 0 || !preprocessingQueueData.length) {
      toast.error("No data to save!");
      return;
    }
    const parsedOriginalData = JSON.parse(originalData); // Parse original data from string
    const updatedData = preprocessingQueueData.filter((item) => {
      const originalItem = parsedOriginalData.find((orig) => orig.key === item.key);
      return JSON.stringify(originalItem) !== JSON.stringify(item); // Compare original with updated
    });
    if (updatedData.length === 0) {
      // toast.info("No changes detected.");
      return;
    }
    const modifiedData = updatedData.filter((item) => modifiedRows.has(item.key));
    const postData = {
      updatedData: modifiedData,
      customer_id: preprocessingQueueData[0]?.customer_id,
      assign_user_id: userId,
      prep_queue_id: atob(id)
    }
    dispatch(updatePrepProcessData({ postData }))
    setOriginalData(JSON.stringify(preprocessingQueueData)); // Update original data
    setModifiedRows(new Set());
    toast.success("Data saved successfully!");
  };
  return (
    <>

      <PageHeader
        HeaderText="Pre Processing Queue"
        Breadcrumb={[{ name: "Pre Processing Queue" }]}
        backButtonUrl="/prep-queue"
      />
      <Card
        title="Pre Processing Queue"
        extra={
          <Flex justify="space-between" align="center" style={{ width: "100%" }}>
            <Button className="primary-btn ml-2" shape="round" type="primary" icon={<DownloadOutlined />} onClick={handleDownloadExcel}>
              Download Template
            </Button>
            <Button className="primary-btn ml-2" shape="round" type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
              Upload Excel
            </Button>
            <Button style={{ backgroundColor: 'green', color: 'white' }} className="ml-2" shape="round" type="default" icon={<SaveOutlined />} onClick={handleSaveData}>
              Save
            </Button>
          </Flex>
        }
      />

      <Form form={form} component={false}>
        <TableComponent dataSource={preprocessingQueueData} columns={columns} onChangeDispatch={handleSaveData} />
      </Form>

      <Modal title="Upload Excel File" open={isModalOpen} onOk={handleUpload} onCancel={handleCancel} okText="Upload">
        <Upload
          beforeUpload={(file) => {
            setFileList([file]);
            return false;
          }}
          fileList={fileList}
          accept=".xlsx, .xls"
        >
          <Button icon={<UploadOutlined />}>Select Excel File</Button>
        </Upload>
      </Modal>
      <Modal
        title="Upload in Progress"
        open={uploadModalVisible}
        footer={[
          <Button key="logs" type="primary" className='primary-btn' onClick={() => navigate("/upload-log")}>
            Go to Upload Logs
          </Button>,
        ]}
        onCancel={() => setUploadModalVisible(false)}
      >
        <p>Data upload is in progress. Please check upload logs.</p>
      </Modal>
    </>
  );
};

export default App;