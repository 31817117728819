import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";
const baseUrl = getBaseEndpointUrl();

export const tryLogin = (postData) => {
  return Http.post(baseUrl + "auth/signin", postData);
};
export const forgotPassword = (postData) => {
  return Http.post(baseUrl + "auth/forgot-password", postData);
};
export const resetPassword = (postData) => {
  return Http.post(baseUrl + "auth/create-password", postData);
};

export const dashboardData = (type, id, staff_admin) => {
  return Http.get(
    baseUrl + `dashboard/admin/${type}/${id}/${staff_admin}`,
    Http.getAuthToken()
  );
};

export const staffDashboardData = (id) => {
  return Http.get(baseUrl + `dashboard/staff/${id}`, Http.getAuthToken());
};

export const dashboardJobData = (query, page, limit, sortby, order, type) => {
  return Http.get(
    baseUrl +
    `dashboard/joblist?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&type=${type}`,
    Http.getAuthToken()
  );
};

export const staffDashboardJobData = (
  query,
  page,
  limit,
  sortby,
  order,
  type,
  id
) => {
  return Http.get(
    baseUrl +
    `dashboard/staffjoblist?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&type=${type}&id=${id}`,
    Http.getAuthToken()
  );
};

export const clientDashboardJobData = (id, job_id) => {
  return Http.get(
    baseUrl + `dashboard/client/${id}/${job_id}`,
    Http.getAuthToken()
  );
};

export const clientDashboardReviewData = (id, job_id) => {
  return Http.get(
    baseUrl + `dashboard/clientreview/${id}/${job_id}`,
    Http.getAuthToken()
  );
};

// <=============================== Item Categories Module =================================>

const itemCategoriesUrl = `${baseUrl}itemcategories/`;

// Get item Categories List
export const itemCategoriesList = (query, page, limit, sortby, order, id) => {
  return Http.get(
    `${itemCategoriesUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${id}`,
    Http.getAuthToken()
  );
};

//Create item Categories
export const createItemCategories = (postData) => {
  return Http.post(`${itemCategoriesUrl}create`, postData, Http.getAuthToken());
};

//Edit item Categories
export const editItemCategories = (postData, updateID) => {
  return Http.put(
    `${itemCategoriesUrl + updateID}`,
    postData,
    Http.getAuthToken()
  );
};

// Delete item Categories
export const itemCategoriesDelete = (deleteID) => {
  return Http.delete(`${itemCategoriesUrl}${deleteID}`, Http.getAuthToken());
};

// <=============================== Item Categories Module =================================>

// <=============================== USER GROUP Module =================================>

const userGroupUrl = `${baseUrl}usergroups/`;

// Get item Categories List
export const userGroupList = (query, page, limit, sortby, order) => {
  return Http.get(
    `${userGroupUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
    Http.getAuthToken()
  );
};

//Create item Categories
export const createUserGroup = (postData) => {
  return Http.post(`${userGroupUrl}add`, postData, Http.getAuthToken());
};

//Edit item Categories
export const editItemUserGroup = (postData, updateID) => {
  const url = `${userGroupUrl}update/${updateID}`;
  return Http.put(url, postData, Http.getAuthToken());
};

// Delete item Categories
export const userGroupDelete = (deleteID) => {
  const url = `${userGroupUrl}delete/${deleteID}`;
  return Http.delete(url);
};

// user group status update
export const userGroupStatusUpdate = (postData, updateID) => {
  return Http.put(`${userGroupUrl}updateStatus/${updateID}`, postData, Http.getAuthToken());
};

// <=============================== USER GROUP Module =================================>


// <=============================== CUSTOMER Module =================================>
const customerUrl = `${baseUrl}customer/`;
export const customerList = (query, page, limit, sortby, order) => {
  return Http.get(
    `${customerUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
    Http.getAuthToken()
  );
};

//Create item Categories
export const createCustomer = (postData) => {
  return Http.post(`${customerUrl}add`, postData, Http.getAuthToken());
};

//Edit item Categories
export const editCustomer = (postData, updateID) => {
  const url = `${customerUrl}update/${updateID}`;
  return Http.put(url, postData, Http.getAuthToken());
};

// Delete item Categories
export const customerDelete = (deleteID) => {
  const url = `${customerUrl}delete/${deleteID}`;
  return Http.delete(`${customerUrl}delete/${deleteID}`, Http.getAuthToken());

};

// user group status update
export const customerStatusUpdate = (postData, updateID) => {
  return Http.put(`${customerUrl}updateStatus/${updateID}`, postData, Http.getAuthToken());
};


// <=============================== CUSTOMER Module =================================>


// <=============================== User Managemnt Module =================================>
const userManagementUrl = `${baseUrl}user_group/`;
export const userManagementList = (query, page, limit, sortby, order) => {
  return Http.get(
    `${userManagementUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
    Http.getAuthToken()
  );
};

//Create item Categories
export const createUserManagement = (postData) => {
  return Http.post(`${userManagementUrl}add`, postData, Http.getAuthToken());
};

//Edit item Categories
export const editUserManagement = (postData, updateID) => {
  const url = `${userManagementUrl}update/${updateID}`;
  return Http.put(url, postData, Http.getAuthToken());
};

// Delete item Categories
export const userManagementDelete = (deleteID) => {
  return Http.delete(`${userManagementUrl}delete/${deleteID}`, Http.getAuthToken());

};

//update user status
export const updateUserStatus = (postData, updateID) => {
  return Http.put(`${userManagementUrl}updateStatus/${updateID}`, postData, Http.getAuthToken());
};

// <=============================== User Managemnt Module =================================>



// <===============================Job Module =================================>

const jobUrl = `${baseUrl}job/`;
export const jobList = (query, page, limit, sortby, order) => {
  return Http.get(
    `${jobUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
    Http.getAuthToken()
  );
};

export const userJobs = ({ query, page, limit, sortby, order, type, userId }) => {
  return Http.delete(
    `${jobUrl}users?query=${query || ''}&page=${page || 1}&limit=${limit || 10}&sortby=${sortby || ''}&order=${order || ''}&type=${type || ''}&id=${userId}`,
    Http.getAuthToken()
  );
};

export const getuserJobsEvents = ({ jobId, userId,event_id }) => {
  return Http.get(
    `${jobUrl}dataEntry/${jobId}?user_id=${userId}&event_id=${event_id || ''}`, // Correct query parameters
    Http.getAuthToken()
  );
};


export const jobListById = ({ editId }) => {
  return Http.get(
    `${jobUrl}/${editId}`,
    Http.getAuthToken()
  );
};

//Create item Categories
export const createJob = (postData) => {
  return Http.post(`${jobUrl}add`, postData, Http.getAuthToken());
};

//Edit item Categories
export const editJob = (postData, updateID) => {
  const url = `${jobUrl}update/${updateID}`;
  return Http.put(url, postData, Http.getAuthToken());
};

// Delete item Categories
export const deleteDelete = (deleteID) => {
  return Http.delete(`${jobUrl}delete/${deleteID}`, Http.getAuthToken());

};
export const jobStatusUpdate = (postData, updateID) => {
  return Http.put(`${jobUrl}updateStatus/${updateID}`, postData, Http.getAuthToken());
};

export const addJobEvents = (updateID,postData)=>{
  return Http.post(`${jobUrl}addJobEVent/${updateID}`, postData, Http.getAuthToken());
}

export const jobEventDelete = (deleteID) => {
  return Http.delete(`${jobUrl}destroyJobEvent/${deleteID}`, Http.getAuthToken());
};

export const userDataJobs = ({ payload }) => {
  return Http.get(
    `${jobUrl}userData/${payload}`,
    Http.getAuthToken()
  );
};

export const allocateDataToUsers = (postData) => {
  return Http.post(`${jobUrl}allocate`, postData, Http.getAuthToken());
};
export const allocateJobDataById = ({ editId }) => {
  return Http.get(
    `${jobUrl}allocation/${editId}`,
    Http.getAuthToken()
  );
};

export const eventDataInJobs = ({ jobId }) => {
  return Http.get(
    `${jobUrl}events_data/${jobId}`,
    Http.getAuthToken()
  );
};

export const uploadLogsData = ({ userId }) => {
  return Http.get(
    `${jobUrl}uploadLogs/${userId}`,
    Http.getAuthToken()
  );
};

export const uploadExcelData = ({ payload }) => {
  return Http.post(`${jobUrl}upload-excel`, payload, Http.getAuthToken())
  
};

export const JobEventUpdate = ({ payload }) => {
  return Http.post(`${jobUrl}update-job-event`, payload, Http.getAuthToken())
};


// <===============================Job Module =================================>


// <=============================== event validation =================================>
const eventValidationUrl = `${baseUrl}event_validation/`;
export const EventValidationByName = ({ editId }) => {
  return Http.get(
    `${eventValidationUrl}find_by_name/${editId}`,
    Http.getAuthToken()
  );
};


// <=============================== event validation =================================> 


// <=============================== event module =================================>
const eventUrl = `${baseUrl}event/`

export const createEvent = (postData) => {
  return Http.post(`${eventUrl}add`, postData, Http.getAuthToken());
};

export const eventList = (query, page, limit, sortby, order) => {
  return Http.get(
    `${eventUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
    Http.getAuthToken()
  );
};
export const eventListById = (id) => {
  return Http.get(
    `${eventUrl}${id}`,
    Http.getAuthToken()
  );
};
export const editEvent = (postData, updateID) => {
  const url = `${eventUrl}update/${updateID}`;
  return Http.put(url, postData, Http.getAuthToken());
};

//update status event
export const eventStatusUpdate = (postData, updateID) => {
  return Http.put(`${eventUrl}updateStatus/${updateID}`, postData, Http.getAuthToken());
};
// <=============================== event module =================================>

// <=============================== workflow module =================================>
const workflowUrl = `${baseUrl}workflow/`
export const createWorkflow = (postData) => {
  return Http.post(`${workflowUrl}add`, postData, Http.getAuthToken());
};

export const workflowList = (query, page, limit, sortby, order) => {
  return Http.get(
    `${workflowUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
    Http.getAuthToken()
  );
};

export const SpecListInWorkflow = (query, page, limit, sortby, order) => {
  return Http.delete(
    `${workflowUrl}getAllSpecId?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
    Http.getAuthToken()
  );
};

export const workflowById = (id) => {
  return Http.get(
    `${workflowUrl}${id}`,
    Http.getAuthToken()
  );
};

export const editWorkFlow = (postData, updateID) => {
  const url = `${workflowUrl}update/${updateID}`;
  return Http.put(url, postData, Http.getAuthToken());
};


//update status workflow
export const workflowStatusUpdate = (postData, updateID) => {
  return Http.put(`${workflowUrl}updateStatus/${updateID}`, postData, Http.getAuthToken());
};

// <=============================== workflow module =================================>


// <=============================== Prep Team Queu module =================================>      
const prepTeamUrl = `${baseUrl}prep_queue/`
export const createPrepTeamQueue = (postData) => {
  return Http.post(`${prepTeamUrl}add`, postData, Http.getAuthToken());
};

export const PrepTeamQueueList = (query, page, limit, sortby, order) => {
  return Http.get(
    `${prepTeamUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
    Http.getAuthToken()
  );
};


export const PrepTeamQueueById = (id) => {
  return Http.get(
    `${prepTeamUrl}${id}`,
    Http.getAuthToken()
  );
};

export const editPrepTeamQueue = (postData, updateID) => {
  const url = `${prepTeamUrl}update/${updateID}`;
  return Http.put(url, postData, Http.getAuthToken());
};

//update status workflow

export const PrepTeamUserData = ({ query = "", page = 0, limit = 0, sortby = "", order = "", userId, }) => {
  const params = new URLSearchParams({
    query,
    page,
    limit,
    sortby,
    order
  }).toString(); // Convert object to query string
  return Http.get(`${prepTeamUrl}users/${userId}?${params}`, Http.getAuthToken());
};
export const prepTeamQUeueById = ({ editId, assign_user_id }) => {
  return Http.get(
    `${prepTeamUrl}preprocessing/${editId}?assign_user_id=${assign_user_id}`,
    Http.getAuthToken()
  );
};
export const editPreprocessing = (postData, updateID) => {
  const url = `${prepTeamUrl}preprocessing/${updateID}`;
  return Http.put(url, postData, Http.getAuthToken());
};

export const allocatePrepDataToUsers = (postData) => {
  return Http.post(`${prepTeamUrl}allocate`, postData, Http.getAuthToken());
};

export const allocatePrepDataById = ({ editId }) => {
  return Http.get(
    `${prepTeamUrl}allocation/${editId}`,
    Http.getAuthToken()
  );
};
export const uploadExcelPrepData = (postData) => {
  return Http.post(`${prepTeamUrl}upload`, postData, Http.getAuthToken());
};
export const updatePrepData = (postData) => {
  return Http.post(`${prepTeamUrl}preprocessingData`, postData, Http.getAuthToken());
};
export const getUploadLogs = ({ userId, query,
  page,
  limit,
  sortby,
  order }) => {
  const params = new URLSearchParams({
    query,
    page,
    limit,
    sortby,
    order
  }).toString(); // Convert object to query string
  return Http.get(`${prepTeamUrl}uploadLogs/${userId}?${params}`, Http.getAuthToken());
}

// <=============================== Prep Team Queu module =================================>      

