import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    EventValidationByName

} from "../../services/api";

const namespace = "eventValidation";

export const EventValidatByName = createAsyncThunk(
    `${namespace}/getJobByID`,
    async ({ editId }, { rejectWithValue }) => {
        try {
            const data = await EventValidationByName({ editId });
            return data?.data?.datas
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
const eventValidationSlice = createSlice({
    name: namespace,
    initialState: {
        eventValidationBynameData: [],
        eventValidationBynameLoading: false,
        eventValidationBynameError: false,

    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(EventValidatByName.pending, (state) => {
                state.loading = true;
            })
            .addCase(EventValidatByName.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(EventValidatByName.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});
export const { resetSuccessFlag } = eventValidationSlice.actions;
export const eventValidationSelector = (state) => state[namespace];
export default eventValidationSlice.reducer;
