import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Table, Button, Card, message, Modal, Upload, Typography, Space, Select, DatePicker } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { usersJobEvent, uploadDataEntryExcel, UpdateJobEVent } from '../../store/reducer/jobSlice';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageHeader from "../../view/pageheader";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify'
import TableComponent from '../CustomComponents/TableComponent';
import { number } from 'yup';
import dayjs from 'dayjs';
const JobsEvents = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const job_name = searchParams.get("job_name");
  const workflow_name = searchParams.get("workflow_name");
  const { uploadLogsData } = useSelector((state) => state.jobs);
  const userJobEventData = useSelector((state) => state.jobs.userJobEventData);
  const jobId = id ? atob(id) : null;

  const { resultData: tableData, eventFieldNames: eventData, eventFieldValidations: validation, nextEventId, previousEventId } = userJobEventData;


  const { data: uploadData } = uploadLogsData || {}
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false); // New state for logs modal
  const [excelData, setExcelData] = useState(null);
  const userId = Number(localStorage.getItem('user_id'));
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState(""); // Store file name
  const [originalRows, setOriginalRows] = useState([]);
  const navigate = useNavigate()
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [dropdownColumns, setDropdownColumns] = useState({});

  useEffect(() => {
    dispatch(usersJobEvent({ jobId, userId }));
    // dispatch(usersJobEventUploadLog({ userId }))
  }, [dispatch, jobId, userId]);



  const handleDownloadTemplate = () => {
    if (!rows.length) {
      message.warning("No data available to download.");
      return;
    }
  
    const dataToDownload = rows;
  
    // Include all columns except those with undefined dataIndex
    const validColumns = columns.filter(
      (col) =>
        col.dataIndex && !["job_barcode_id", "event_id"].includes(col.dataIndex)
    );
  
    const headers = validColumns.map((col) => col.title);
    const fieldKeys = validColumns.map((col) => col.dataIndex);
  
    // Track fields that need special handling
    const dropdownColumns = {};
    const numericFields = new Set();
    const dateFields = new Set(); // Track date fields (fieldtype == 4)
  
    eventData?.eventFieldNames?.[0]?.event_fields.forEach((field) => {
    
      
      if (field.fieldtype == 4) {
        dateFields.add(field.field_name); // Add date fields to the Set
      }

  
      if (field.fieldtype == 2) {
        numericFields.add(field.field_name);
      }
  
      if (field.fieldtype == 3) {
        const options =
          validation?.eventFieldValidations
            ?.filter((v) => v.field_id == field.field_id)
            ?.map((data) => data.validationvalue) || [];
        if (options.length) dropdownColumns[field.field_name] = options;
      }
    });
  
    setDropdownColumns(dropdownColumns);
  
    // Format data for Template Sheet
    const formattedData = dataToDownload.map((row, index) => {
      let formattedRow = {};
      fieldKeys.forEach((key) => {
        if (key == "box") {
          formattedRow["Box"] = index + 1;
        } else if (key === "Alt Box") {
          formattedRow["Alt Box"] = index;
        } else {
          let value = row[key] !== undefined && row[key] !== null ? row[key] : " ";
  
          // Convert to number if field is numeric
          if (numericFields.has(key)) {
            value = Number(value) || null;
          }
  
          // Handle date fields (fieldtype == 4)
          if (dateFields.has(key)) {
            value = value ; // Ensures no special characters or whitespace
          }
  
          formattedRow[key] = value;
        }
      });
      return formattedRow;
    });
  
    // Rest of your code remains the same...
    console.log(formattedData);
  
    // Create worksheet for Template
    const templateSheet = XLSX.utils.json_to_sheet(formattedData);
    templateSheet["!cols"] = headers.map((header) => ({ wch: header.length + 2 }));
  
    // Format Dropdown Data Sheet
    const dropdownArray = [];
  
    // Create worksheet for Dropdown Data
    const dropdownSheet = XLSX.utils.json_to_sheet(dropdownArray);
    dropdownSheet["!cols"] = Object.keys(dropdownColumns).map(() => ({ wch: 20 }));
  
    // Apply dropdown validation in Template Sheet
    Object.entries(dropdownColumns).forEach(([columnName, options]) => {
      const colIndex = headers.indexOf(columnName);
      if (colIndex !== -1) {
        const dropdownRange = {
          s: { r: 1, c: colIndex },
          e: { r: 1000, c: colIndex },
        };
  
        templateSheet["!dataValidation"] = templateSheet["!dataValidation"] || [];
        templateSheet["!dataValidation"].push({
          type: "list",
          allowBlank: true,
          sqref: XLSX.utils.encode_range(dropdownRange),
          formula1: options.join(","),
        });
      }
    });
  
    // Create workbook and append both sheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, templateSheet, "Template");
    if (dropdownArray.length > 0) {
      XLSX.utils.book_append_sheet(workbook, dropdownSheet, "List Data");
    }
  
    // Write and download the file
    XLSX.writeFile(workbook, "Template.xlsx");
  };
  




  const handleFileChange = ({ file }) => {
    const allowedExtensions = ["xlsx", "xls", "xlsm", "xltx", "ods", "xlsb"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Invalid file type! Please upload a valid Excel file.");
      return;
    }
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert sheet data to JSON and ensure empty cells are captured
      let jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false, defval: null });

      // Extract headers from uploaded Excel file
      const excelHeaders = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

      // Extract expected headers from columns.dataIndex
      const expectedHeaders = columns.map(col => col.dataIndex.toLowerCase());

      // Normalize Excel headers to match dataIndex format
      const normalizedExcelHeaders = excelHeaders.map(header => header.toLowerCase());

      // Check if all expected headers are present in the uploaded file
      const missingHeaders = expectedHeaders.filter(header => !normalizedExcelHeaders.includes(header));
      const extraHeaders = normalizedExcelHeaders.filter(header => !expectedHeaders.includes(header));

      if (missingHeaders.length > 0 || extraHeaders.length > 0) {
        toast.error(`File Header Columns doesn't match`)
        return;
      }

      // Process the data if headers match
      jsonData = jsonData.map((row) => {
        let formattedRow = {};
        const dropdownColumns = {};
        eventData?.eventFieldNames?.[0]?.event_fields.forEach((field) => {
          if (field.fieldtype === 3) {
            const options = validation?.eventFieldValidations
              ?.filter((v) => v.field_id == field.field_id)
              ?.map((data) => data.validationvalue) || [];
            if (options.length) dropdownColumns[field.field_name] = options;
          }
        });


        setDropdownColumns(dropdownColumns);
        Object.keys(row).forEach((key) => {
          let value = row[key];
          // Validate dropdown values
          // if (dropdownColumns[key] && !dropdownColumns[key].includes(value)) {
          //   message.error(
          //     `Invalid value  . Please select from dropdown.`
          //   );
          //   return;
          // }

          // Skip date conversion for the "Spec" and "Barcode" columns
          if (key == "Spec" || key == "Barcode") {
            formattedRow[key] = value;
            return;
          }

          // Handling Excel serialized date values
          if (typeof value === "number" && value > 40000 && value < 99999) {
            // Ensure it's kept as a number instead of a date
            formattedRow[key] = row[key]; 
          }
          // Handling date formats like DD/MM/YY and converting to DD/MM/YYYY
          else if (typeof value === "string" && /^\d{2}\/\d{2}\/\d{2}$/.test(value)) {
            let [day, month, year] = value.split("/");
            year = parseInt(year, 10) < 50 ? `20${year}` : `19${year}`;
            formattedRow[key] = `${day}/${month}/${year}`;
          }
          // Ensuring numeric values remain as numbers
          else if (!isNaN(value) && typeof value === "number") {
            formattedRow[key] = value;
          } else {
            formattedRow[key] = value;
          }
        });
      
     
        return formattedRow;
      });
console.log(jsonData,"kkkkkkkkkkkkkk11");

      setExcelData(jsonData); // Store the transformed data
    };

    reader.readAsBinaryString(file);
  };

  const handleUpload = async () => {
    if (!excelData) {
      message.error('Please select an Excel file to upload');
      return;
    }
    const modifiedRows = getExcelModifiedRows();
    console.log(modifiedRows, "modifiedRows");

    const payload = {
      data: modifiedRows,
      jobId,
      userId,
      event_id: eventData?.eventFieldNames[0]?.event_id
    }
    console.log(payload, "payload12222");


    try {
      const response = dispatch(uploadDataEntryExcel({ postData: payload }))
      setFileName("")
      setIsModalOpen(false);
      setUploadModalVisible(true);
    } catch (error) {
      message.error('File upload failed');
    }
  };
  const handleNextClick = () => {
    if (nextEventId) {
      dispatch(usersJobEvent({ jobId, userId, event_id: nextEventId }));
    } else {
      console.warn("Next Event ID is not available.");
    }
  };

  const handlePreviousClick = () => {
    if (previousEventId) {
      dispatch(usersJobEvent({ jobId, userId, event_id: previousEventId }));
    } else {
      console.warn("Previous Event ID is not available.");
    }
  }

  const getValidationRules = (validations) => {
    console.log(validations, "validations==");


    let rules = [];
    if (!validations || validations.length === 0) return rules;

    validations.forEach((validation) => {
      const { validation: type, validationvalue, minvalue, maxvalue, mandatory, fieldtype } = validation;
      console.log(validation, type, "validationvalidation==");

      if (mandatory == 1) {
        rules.push({
          required: true,
          message: "This field is required",
        });
      }

      if (mandatory == 1 && fieldtype == 3) {
        rules.push({
          required: true,
          message: "This field is required",
        });
        rules.push({
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error("Field is required"));
            }
            return Promise.resolve();
          },
        });
      }
      console.log(type, "validationstyoe==");

      switch (type) {

        case 1: // Numeric Length
          rules.push({
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Allow empty values (optional)
              }

              if (!/^\d+$/.test(value)) {
                return Promise.reject("Only numbers are allowed!");
              }

              const length = value.length;

              if (length >= parseInt(minvalue) && length <= parseInt(maxvalue)) {
                return Promise.resolve(); // Validation passed
              }

              return Promise.reject(
                `Length must be between ${minvalue} and ${maxvalue} characters`
              );
            },
          });
          break;
        case 2: // Decimal
          rules.push({
            validator: (_, value) => {
              if (!value) return Promise.resolve(); // Allow empty values

              if (!/^\d+(\.\d+)?$/.test(value)) {
                return Promise.reject("Only numeric values are allowed!");
              }

              const regex = new RegExp(`^\\d+(\\.\\d{1,${validationvalue}})?$`);
              return regex.test(value)
                ? Promise.resolve()
                : Promise.reject(`Value must have up to ${validationvalue} decimal places`);
            },
          });
          break;

        case 3: // Greater Than
          rules.push({
            validator: (_, value) => {
              if (!value) return Promise.resolve(); // Allow empty values

              if (!/^\d+(\.\d+)?$/.test(value)) {
                return Promise.reject("Only numeric values are allowed!");
              }

              return parseFloat(value) > Number(validationvalue)
                ? Promise.resolve()
                : Promise.reject(`Value must be greater than ${validationvalue}`);
            },
          });
          break;

        case 4: // Less Than
          rules.push({
            validator: (_, value) => {
              if (!value) return Promise.resolve(); // Allow empty values

              if (!/^\d+(\.\d+)?$/.test(value)) {
                return Promise.reject("Only numeric values are allowed!");
              }

              return parseFloat(value) < parseFloat(validationvalue)
                ? Promise.resolve()
                : Promise.reject(`Value must be less than ${validationvalue}`);
            },
          });
          break;
        case 6: // String Length
          rules.push({
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Allow empty values (optional)
              }

              const length = value.length;

              if (length >= parseInt(minvalue) && length <= parseInt(maxvalue)) {
                return Promise.resolve(); // Validation passed
              }

              return Promise.reject(
                `Length must be between ${minvalue} and ${maxvalue} characters`
              );
            },
          });
          break;

        case 9: // Alphabetic
          rules.push({
            pattern: /^[A-Za-z]+$/,
            message: "Value must contain only alphabetic characters",
          });
          break;

        case 10: // Alphanumeric
          rules.push({
            pattern: /^[A-Za-z0-9]+$/,
            message: "Value must contain only letters and numbers",
          });
          break;

        case 11: // Format (Assuming regex format)
          if (validationvalue === "MM/DD/YYYY") {
            rules.push({
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
                return regex.test(value)
                  ? Promise.resolve()
                  : Promise.reject(`Value must match the format: ${validationvalue} (e.g., 12/31/2025)`);
              },
            });
          } else if (validationvalue === "DD/MM/YYYY") {
            rules.push({
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                const regex = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
                return regex.test(value)
                  ? Promise.resolve()
                  : Promise.reject(`Value must match the format: ${validationvalue} (e.g., 31/12/2025)`);
              },
            });
          } else {
            rules.push({
              pattern: new RegExp(validationvalue),
              message: `Value must match the format: ${validationvalue}`,
            });
          }
          break;


        case 12: // Past Dates Allowed
          rules.push({
            validator: (_, value) => {
              if (!value) return Promise.resolve(); // Allow empty values (optional)

              const inputDate = new Date(value);
              const today = new Date();
              const yesterday = new Date();

              // Set today's date to midnight
              today.setHours(0, 0, 0, 0);
              // Get yesterday's date (normalize time)
              yesterday.setDate(yesterday.getDate() - 1);
              yesterday.setHours(0, 0, 0, 0);

              return inputDate.getTime() <= yesterday.getTime()
                ? Promise.resolve()
                : Promise.reject("Only past dates up to yesterday are allowed and Value must match the format:MM/DD/YYYY");
            },
          });
          break;

        case 13: // Time format (HH:MM)
          if (validationvalue == "hh::mm::ss") {
            rules.push({
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                const regex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
                return regex.test(value)
                  ? Promise.resolve()
                  : Promise.reject(`Value must match the format: ${validationvalue} (e.g., 02:40:04)`);
              },
            });
          } else if (validationvalue === "hh::mm") {
            rules.push({
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
                return regex.test(value)
                  ? Promise.resolve()
                  : Promise.reject(`Value must match the format: ${validationvalue} (e.g., 02:40)`);
              },
            });
          } else {
            rules.push({
              pattern: new RegExp(validationvalue),
              message: `Value must match the format: ${validationvalue} (e.g., 02:40)`,
            });
          }
          break;

        case 14: // Less Than or Equal
          rules.push({
            validator: (_, value) => {
              if (!value) return Promise.resolve(); // Allow empty values

              if (!/^\d+(\.\d+)?$/.test(value)) {
                return Promise.reject("Only numeric values are allowed!");
              }

              return parseFloat(value) <= parseFloat(validationvalue)
                ? Promise.resolve()
                : Promise.reject(`Value must be less than or equal to ${validationvalue}`);
            },
          });
          break;

        case 15: // Greater Than or Equal
          rules.push({
            validator: (_, value) => {
              if (!value) return Promise.resolve(); // Allow empty values

              if (!/^\d+(\.\d+)?$/.test(value)) {
                return Promise.reject("Only numeric values are allowed!");
              }

              return parseFloat(value) >= parseFloat(validationvalue)
                ? Promise.resolve()
                : Promise.reject(`Value must be greater than or equal to ${validationvalue}`);
            },
          });
          break;

        case 16: // Format (Regex)
          if (validationvalue == "DD/MM/YYYY hh:mm:ss") {
            rules.push({
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                const regex = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} (?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
                return regex.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                    `Value must match the format: ${validationvalue} (e.g., 03/10/2025 02:40:04)`
                  );
              },
            });
          } else if (validationvalue === "DD/MM/YYYY hh:mm") {
            rules.push({
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                const regex = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4} (?:[01]\d|2[0-3]):[0-5]\d$/;
                return regex.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                    `Value must match the format: ${validationvalue} (e.g., 03/10/2025 02:40)`
                  );
              },
            });
          } else if (validationvalue == "MM/DD/YYYY hh:mm:ss") {
            rules.push({
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4} (?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
                return regex.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                    `Value must match the format: ${validationvalue} (e.g., 10/03/2025 02:40:04)`
                  );
              },
            });
          } else {
            rules.push({
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                // Corrected regex for "MM/DD/YYYY hh:mm"
                const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4} (?:[01]\d|2[0-3]):[0-5]\d$/;
                return regex.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                    `Value must match the format: ${validationvalue} (e.g., 10/03/2025 02:40)`
                  );
              },
            });
          }
          break;

        default:
          break;
      }
    });

    return rules;
  };


  function evaluateDynamicExpression(expression, idToNameMap, nameToValueMap) {
    console.log("nameToValueMap:", nameToValueMap);

    // Extract numbers (IDs) and operators
    let tokens = expression.match(/(\d+|\+|\-|\*|\/)/g) || [];

    // Replace IDs with their corresponding field names
    let namedExpression = tokens.map((token) => idToNameMap[token] || token).join(" ");

    console.log("idToNameMap:", idToNameMap);
    console.log("Named Expression:", namedExpression);

    // Replace field names with their actual values (default to 0 if undefined)
    let finalExpression = namedExpression
      .split(" ")
      .map((token) => (nameToValueMap[token] !== undefined ? nameToValueMap[token] : token))
      .join(" ");

    console.log("Final Expression to Evaluate:", finalExpression);

    // Evaluate the final numeric expression
    try {
      return eval(finalExpression);
    } catch (error) {
      console.error("Invalid expression", error);
      return null;
    }
  }

  useEffect(() => {
    if (!eventData?.eventFieldNames?.[0]?.event_fields) return;

    const fieldMappings = eventData.eventFieldNames[0].event_fields
      .filter((field) => !["Spec", "Barcode", "Box", "Alt Box"].includes(field.field_name)) // Remove Spec & Barcode
      .map((field) => ({
        title: field.field_name,
        dataIndex: field.field_name, // Use field_name as key
        key: field.field_id,
        render: (_, record, index) => {
          const fieldValidation =
            validation?.eventFieldValidations?.filter((v) => v.field_id == field.field_id) || [];


          // Unique name for Form.Item
          const fieldPath = (index) => [index, field.field_name];
          const title = field.field_name
          const fieldType = field.fieldtype




          const dropDownOptions =
            fieldType === 3
              ? fieldValidation.map((data) => ({ label: data.validationvalue, value: data.validationvalue }))
              : [];
          if (fieldType === 6) {
            // Get all validation values as an array
            const validationValues = fieldValidation.map((v) => v.validationvalue); // ["16,+,17", "16,+,19"]

            if (validationValues.length > 0) {
              const idToNameMap = Object.fromEntries(
                eventData.eventFieldNames[0].event_fields.map((f) => [f.field_id, f.field_name])
              );

              const nameToValueMap = Object.fromEntries(
                eventData.eventFieldNames[0].event_fields.map((f) => [
                  f.field_name,
                  record[f.field_name] || 0, // Default to 0 if no value
                ])
              );

              // Include Spec and Barcode in nameToValueMap
              nameToValueMap["Spec"] = record["spec"] || 0;
              nameToValueMap["Barcode"] = record["barcode"] || 0;

              // Evaluate all expressions and sum the results
              const totalSum = validationValues
                .map((expression) => evaluateDynamicExpression(expression, idToNameMap, nameToValueMap))
                .reduce((sum, value) => sum + value, 0);

              console.log("Total Sum of Expressions:", totalSum);

              return <span>{totalSum}</span>;
            }
          }
          const dateFormat =
          fieldValidation.some((v) => v.validationvalue === "MM/DD/YYYY")
            ? "MM/DD/YYYY"
            : fieldValidation.some((v) => v.validationvalue === "DD/MM/YYYY")
            ? "DD/MM/YYYY"
            : null;
          return (
            <Form.Item name={fieldPath(index)} rules={getValidationRules(fieldValidation)} noStyle>
            <div>
              {fieldType === 3 ? (
                <Select
                  options={dropDownOptions}
                  placeholder="Select Value"
                  value={record[field.field_name] || undefined}
                  onChange={(value) => {
                    setRows((prevRows) =>
                      prevRows.map((row, rowIndex) =>
                        rowIndex === index ? { ...row, [field.field_name]: value } : row
                      )
                    );
                    form.setFieldsValue({ [fieldPath(index)]: value });
                  }}
                  style={{ width: "300px" }}
                />
              ) : fieldType === 4 && dateFormat ? (
                <DatePicker
                  format={dateFormat}
                  value={record[field.field_name] ? dayjs(record[field.field_name], dateFormat) : null}
                  onChange={(date, dateString) => {
                    setRows((prevRows) =>
                      prevRows.map((row, rowIndex) =>
                        rowIndex === index ? { ...row, [field.field_name]: dateString } : row
                      )
                    );
                    form.setFieldsValue({ [fieldPath(index)]: dateString });
                  }}
                />
              ) : (
                <Input
                  type={["Box", "Alt Box"].includes(field.field_name) ? "number" : "text"}
                  value={record[field.field_name] || ""}
                  placeholder="Enter Value"
                  onChange={(e) => {
                    const { value } = e.target;
                    setRows((prevRows) =>
                      prevRows.map((row, rowIndex) =>
                        rowIndex === index ? { ...row, [field.field_name]: value } : row
                      )
                    );
                  }}
                />
              )}

              <Form.Item shouldUpdate dependencies={[fieldPath(index)]} noStyle>
                {({ getFieldError }) => {
                  const errors = getFieldError(fieldPath(index));
                  return errors.length > 0 ? (
                    <div style={{ color: "red", fontSize: "12px" }}>{errors.join(", ")}</div>
                  ) : null;
                }}
              </Form.Item>
            </div>
          </Form.Item>
          );
        },
      }));

    // Include barcode and job_barcode_id in the columns
    const tableColumns = [
      { title: "Spec", dataIndex: "spec", key: "spec" },
      { title: "Barcode", dataIndex: "barcode", key: "barcode" },
      {
        title: "Box",
        dataIndex: "box",
        key: "box",
        render: (_, __, index) => index + 1, // Sequential numbering
      },
      {
        title: "Alt Box",
        dataIndex: "Alt Box",
        key: "Alt Box",
        render: (_, __, index) => index, // Sequential numbering
      },
      ...fieldMappings,
    ];

    setColumns(tableColumns);
  }, [eventData, validation]);
  // Update columns when eventData changes


  useEffect(() => {
    if (tableData && tableData?.data) {
      // Transform datas to match column structure
      const formattedData = tableData && tableData?.data.map((row, index) => {
        const formattedRow = {
          job_barcode_id: row.job_barcode_id,
          barcode: row.barcode,
          spec: row.spec,
        };

        // Map event field values into correct columns
        row.event_fields.forEach((field) => {
          formattedRow[field.event_field_name] = field.event_field_value;
        });

        return formattedRow;
      });

      setRows(formattedData);
      setOriginalRows(formattedData);
    }
  }, [tableData])
  const getExcelModifiedRows = () => {
    if (!excelData || !eventData?.eventFieldNames?.[0]?.event_fields) return [];
  
    return excelData.map((excelRow) => {
      // Convert Excel row field names to match your originalRows structure
      const excelBarcode = excelRow.barcode || excelRow.Barcode;
      const excelSpec = excelRow.spec || excelRow.Spec;
  
      // Find the matching original row by both barcode AND spec for precise matching
      const matchingRow = originalRows.find(row => 
        String(row.barcode) === String(excelBarcode) && 
        String(row.spec) === String(excelSpec)
      );
  
      if (!matchingRow) {
        console.error(`No matching row found for Barcode: ${excelBarcode}, Spec: ${excelSpec}`);
        return null; // Skip this row if no match found
      }
  
      // Get the correct job_barcode_id from the matched original row
      const job_barcode_id = matchingRow.job_barcode_id;
  
      // Prepare updated fields with proper field name mapping
      const updatedFields = {
        Barcode: excelBarcode,
        Spec: excelSpec,
        Box: excelRow.Box || null
      };
  
      // Add other fields from columns
      columns.forEach((column) => {
        const fieldName = column.dataIndex;
        // Skip fields we've already handled
        if (['barcode', 'spec', 'Box'].includes(fieldName)) return;
        
        const excelFieldName = fieldName === 'spec' ? 'Spec' : 
                             fieldName === 'barcode' ? 'Barcode' : 
                             fieldName;
        
        if (excelRow[excelFieldName] !== undefined) {
          updatedFields[fieldName] = excelRow[excelFieldName];
        }
      });
  
      return {
        job_barcode_id, // Unique for each matched row
        event_id: eventData.eventFieldNames[0].event_id,
        updated_fields: updatedFields
      };
    }).filter(Boolean); // Remove any null entries from unmatched rows
  };

  const getModifiedRows = () => {
    return rows
      .map((row, index) => {
        const originalRow = originalRows[index] || {};
        let updatedFields = {};
        // Check for changed values and store them using event_field_id
        columns?.forEach((field) => {
          const fieldName = field?.dataIndex;
          const fieldId = field?.key;
          const newValue = row[fieldName];


          const originalValue = originalRow[fieldName];

          if (newValue !== originalValue) {
            updatedFields[fieldId] = newValue; // Use fieldId as key


          }
        });
        console.log(updatedFields, "eeeeeeeeee");
        // Only return rows where updates exist
        return Object.keys(updatedFields).length > 0
          ? {
            job_barcode_id: row.job_barcode_id,
            event_id: tableData?.data[0]?.event_id, // Ensure event_id is included
            updated_fields: updatedFields,
          }
          : null;
      })
      .filter(Boolean); // Remove null values
  };

  const handleSave = () => {
    const errors = form.getFieldsError(); // Get all fields' validation errors
    const hasErrors = errors.some(({ errors }) => errors.length > 0); // Check if any field has errors
    if (hasErrors) {
      toast.error("Please correct the errors");
      return; // Stop execution if errors exist
    }
    const modifiedRows = getModifiedRows();
    console.log(modifiedRows, "modifiedRows");

    dispatch(UpdateJobEVent({ postData: modifiedRows }));
    setOriginalRows([...rows]);
    toast.success('Job Event updated successfully')
    navigate('/jobs')
  };

  return (
    <div>
      <PageHeader
        HeaderText={
          <>
            <Link to="/jobs">{atob(job_name)}</Link> &gt; {atob(workflow_name)}
          </>
        }
        Breadcrumb={[{ name: 'Jobs' }]}
        backButtonUrl="/jobs"
      />

      <Card
        title={`Event : ${(eventData?.eventFieldNames[0]?.event_fields[0]?.event_name)}`}
        extra={
          <>
            <Button
              className="primary-btn ml-2"
              shape="round"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsModalOpen(true)}
            >
              Upload Excel
            </Button>
            <Button style={{ backgroundColor: 'green', color: 'white' }} className="ml-2" shape="round" type="default" icon={<SaveOutlined />} onClick={handleSave}>
              Save
            </Button>
          </>
        }
      >
        <Form form={form} component={false}>
          <TableComponent
            bordered
            dataSource={rows}
            columns={columns}
            rowClassName="editable-row"

          />
          <div className="float-end">
            {/* Prev Button (first in order) */}
            <Button
              className="primary-btn"
              shape="round"
              type="default"
              onClick={handlePreviousClick}
              disabled={previousEventId === null} // Disable if previousEventId is null
            >
              <ArrowLeftOutlined /> Prev
            </Button>

            {/* Next Button (second in order) */}
            <Button
              className="primary-btn"
              shape="round"
              type="default"
              onClick={handleNextClick}
              disabled={nextEventId === null} // Disable if nextEventId is null
              style={{ marginLeft: "10px" }} // Adds spacing between buttons
            >
              Next <ArrowRightOutlined />
            </Button>
          </div>
        </Form>
      </Card>
      <Modal
        title={<Typography.Title level={4}>Upload Excel File</Typography.Title>}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
      >
        <Card bordered={false} style={{ textAlign: "center", padding: 20 }}>
          <Upload
            beforeUpload={() => false}
            onChange={handleFileChange}
            accept=".xlsx, .xls"
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          {fileName && (
            <Typography.Text type="secondary" style={{ display: "block", marginTop: 10 }}>
              Selected File: {fileName}
            </Typography.Text>
          )}
          <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
            <Button onClick={handleDownloadTemplate} type="default" block>
              Download Template
            </Button>
            <Button className='primary-btn' type="primary" onClick={handleUpload} block>
              Submit
            </Button>
          </Space>
        </Card>
      </Modal>
      <Modal
        title="Upload in Progress"
        open={uploadModalVisible}
        footer={[
          <Button key="logs" type="primary" className='primary-btn' onClick={() => navigate("/job-event-log")}>
            Go to Upload Logs
          </Button>,
        ]}
        onCancel={() => setUploadModalVisible(false)}
      >
        <p>Data upload is in progress. Please check upload logs.</p>
      </Modal>
    </div>
  );
};

export default JobsEvents;