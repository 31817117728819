import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    jobList,
    deleteDelete,
    createJob,
    editJob,
    jobListById,
    jobStatusUpdate,
    userJobs,
    getuserJobsEvents,
    userDataJobs,
    allocateDataToUsers,
    eventDataInJobs,
    uploadLogsData,
    allocateJobDataById,
    uploadExcelData,
    JobEventUpdate,
    addJobEvents,
    jobEventDelete

} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "jobs";

// Async thunk for fetching the Portal customer list
export const getJobsList = createAsyncThunk(
    `${namespace}/getJobsList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await jobList(query, page, limit, sortby, order);


            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

//users job list 
export const getUsersJobsList = createAsyncThunk(
    `${namespace}/getUsersJobsList`,
    async (userId, { rejectWithValue }) => {
        try {
            const data = await userJobs(userId);
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
// Async thunk for fetching the Portal Job by id
export const getJobByID = createAsyncThunk(
    `${namespace}/getJobByID`,
    async ({ editId }, { rejectWithValue }) => {
        try {


            const data = await jobListById({ editId });
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Portal users data in job module for allocate data
export const allocateJobUsers = createAsyncThunk(
    `${namespace}/allocateJob`,
    async ({ jobId }, { rejectWithValue }) => {
        try {
            const data = await userDataJobs({ payload: jobId });
            const decryptedData = JSON.parse(
                EncryptDecrypt.decryptdata(data.data.datas, secretKey)
            );
            return { datas: decryptedData };
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
export const jobAllocationById = createAsyncThunk(
    `${namespace}/jobAllocationById`,
    async (
        { editId },

        { rejectWithValue }
    ) => {
        try {

            const data = await allocateJobDataById({ editId });
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

//events data in shoing in job module
export const getEventDataInJobs = createAsyncThunk(
    `${namespace}/getEventDataInJobs`,
    async ({ jobId }, { rejectWithValue }) => {
        try {
            const data = await eventDataInJobs({ jobId });
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


// craete data allocating in admin module 

export const addDataAllocation = createAsyncThunk(
    `${namespace}/addDataAllocation`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);


            const data = await allocateDataToUsers({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Portal customer
export const addJob = createAsyncThunk(
    `${namespace}/addJob`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);


            const data = await createJob({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Portal Customer data
export const updateJob = createAsyncThunk(
    `${namespace}/updateJob`,
    async ({ postData, updateID }, { rejectWithValue }) => {


        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);


            const data = await editJob({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for deleting a Portal User group
export const deleteJob = createAsyncThunk(
    `${namespace}/deleteJob`,
    async ({ deleteID }, { rejectWithValue }) => {


        try {
            const data = await deleteDelete(deleteID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
export const updateJobStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await jobStatusUpdate({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const addJobEvent = createAsyncThunk(
    `${namespace}/addJobEvent`,
    async ({ updateID,postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await addJobEvents(updateID,{payload});
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deleteJobEvent = createAsyncThunk(
    `${namespace}/deleteJobEvent`,
    async ({ updateID }, { rejectWithValue }) => {
        try {
            const data = await jobEventDelete(updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const usersJobEvent = createAsyncThunk(
    `${namespace}/UsersJobEvent`,
    async ({ jobId, userId, event_id }, { rejectWithValue }) => {
        try {

            const data = await getuserJobsEvents({ jobId, userId, event_id });
            const eventFieldNames = JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.eventFieldNames, secretKey)
            );
            const eventFieldValidations = JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.eventFieldValidations, secretKey)
            );

            // Parse and decrypt the datas (resultData) response
            const resultData = JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );

            return { eventFieldNames, eventFieldValidations, resultData, nextEventId: data?.data?.nextEventId, previousEventId: data?.data?.previousEventId };
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const usersJobEventUploadLog = createAsyncThunk(
    `${namespace}/usersJobEventUploadLog`,
    async ({ userId }, { rejectWithValue }) => {
        try {
            const data = await uploadLogsData({ userId });
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)
export const uploadDataEntryExcel = createAsyncThunk(
    `${namespace}/uploadDataEntryExcel`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await uploadExcelData({ payload: { payload } });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)

export const UpdateJobEVent = createAsyncThunk(
    `${namespace}/UpdateJobEVent`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await JobEventUpdate({ payload: { payload } });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)



// User Group Slice
const jobSlice = createSlice({
    name: namespace,
    initialState: {
        jobLoading: false,
        jobListData: [], // Ensure this structure matches the expected format
        jobListError: false,
        jobListErrorMessage: "",
        userJosData: [], // Ensure this structure matches the expected formatting
        jobListByIdData: [],
        jobListByIdError: false,
        addJobSuccess: false,
        addJobError: false,
        addJobErrorMessage: "",
        updateJobSuccess: false,
        updateJobError: false,
        updateJobErrorMessage: "",
        deleteJobSuccess: false,
        deleteJobError: false,
        deleteJobErrorMessage: "",
        jobStatusSuccess: false,
        jobStatusError: false,
        jobStatusErrorMessage: "",
        userJobEventData: [],
        userDataINJObs: [],
        addDataAllocationSuccess: false,
        addDataAllocationError: false,
        addDataAllocationErrorMessage: "",
        getEventDataInJobsResponse: [],
        uploadLogsData: [],
        allocateJobByIdData: [],
        uploadExcelDataSuccess: false,
        uploadExcelDataError: false,
        uploadExcelDataErrorMessage: "",
        jobEventUpdateSuccess: false,
        jobEventUpdateError: false,
        jobEventUpdateErrorMessage: "",
        addJobEventsSuccess: false,
        addJobEventsError: false,
        addJobEventsSuccess: "",
        addJobEventsErrorMessage: "",
        jobEventDeleteSuccess: false,
        jobEventDeleteError: false,
        jobEventDeleteErrorMessage: "",


    },

    reducers: {
        resetAllocationData: (state) => {
            state.allocateJobByIdData = [];
        },
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },

    },

    extraReducers: (builder) => {
        builder
            // Handle getUserGroupList
            .addCase(getJobsList.pending, (state) => {
                state.jobLoading = true;
            })
            .addCase(getJobsList.fulfilled, (state, action) => {

                const data = action.payload;
                state.jobListData = data;
                state.addJobSuccess = false;
                state.jobEventUpdateSuccess = false;

                state.jobLoading = false;
            })

            .addCase(getJobsList.rejected, (state) => {
                state.jobLoading = false;

            })
            .addCase(getUsersJobsList.pending, (state) => {
                state.jobLoading = true;
            })
            .addCase(getUsersJobsList.fulfilled, (state, action) => {
                const data = action.payload;
                state.userJosData = data;
                state.jobLoading = false;
            })
            .addCase(getUsersJobsList.rejected, (state) => {
                state.jobLoading = false;
            })

            .addCase(getJobByID.pending, (state) => {
                state.jobLoading = true;
            })
            .addCase(getJobByID.fulfilled, (state, action) => {
                const data = action.payload;
                state.jobListByIdData = data;
                state.jobLoading = false;
            })
            .addCase(getJobByID.rejected, (state) => {
                state.jobLoading = false;
            })
            .addCase(addJob.pending, (state) => {
                state.jobLoading = true;
                state.addJobError = false;
                state.addJobSuccess = false;
            })
            .addCase(addJob.fulfilled, (state) => {
                state.jobLoading = false;
                state.addJobSuccess = true;


            })
            .addCase(addJob.rejected, (state, action) => {
                state.jobLoading = false;
                state.addJobError = true;

                state.addJobErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })

            // Handle updateUserGroup
            .addCase(updateJob.pending, (state) => {
                state.jobLoading = false;
                state.updateJobError = false;
                state.updateJobErrorMessage = "";
            })
            .addCase(updateJob.fulfilled, (state) => {
                state.updateJobSuccess = true;
            })
            .addCase(updateJob.rejected, (state, action) => {
                state.updateJobError = true;
                state.updateJobErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })

            // Handle deleteUserGroup
            .addCase(deleteJob.pending, (state) => {
                state.jobLoading = true;
                state.deleteJobError = false;
                state.deleteJobErrorMessage = false;
            })
            .addCase(deleteJob.fulfilled, (state) => {
                state.jobLoading = false;
                state.deleteJobSuccess = true;
            })
            .addCase(deleteJob.rejected, (state, action) => {
                state.jobLoading = false;
                state.deleteJobError = true;
                state.deleteJobErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })
            .addCase(updateJobStatus.pending, (state, action) => {
                state.jobLoading = true;
                state.jobStatusError = false;
                state.jobStatusErrorMessage = "";

            })
            .addCase(updateJobStatus.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.jobStatusSuccess = true;
            })
            .addCase(updateJobStatus.rejected, (state, action) => {
                state.jobLoading = false;
                state.jobStatusError = true;
                state.jobStatusErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })
            .addCase(usersJobEvent.pending, (state) => {
                state.jobLoading = true;
            })
            .addCase(usersJobEvent.fulfilled, (state, action) => {
                state.jobLoading = false
                state.userJobEventData = { ...action.payload };  // Ensure new reference

            })
            .addCase(usersJobEvent.rejected, (state, action) => {
                state.jobLoading = false;
            })
            .addCase(allocateJobUsers.pending, (state, action) => {
                state.jobLoading = true;
            })
            .addCase(allocateJobUsers.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.userDataINJObs = action.payload;
            })
            .addCase(allocateJobUsers.rejected, (state, action) => {
                state.jobLoading = false;
            })
            .addCase(addDataAllocation.pending, (state, action) => {
                state.jobLoading = true;
            })
            .addCase(addDataAllocation.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.addDataAllocationSuccess = true;
            })
            .addCase(addDataAllocation.rejected, (state, action) => {
                state.jobLoading = false;
                state.addDataAllocationError = true;
            })
            .addCase(getEventDataInJobs.pending, (state, action) => {
                state.jobLoading = true;
            })
            .addCase(getEventDataInJobs.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.getEventDataInJobsResponse = action.payload;
            })
            .addCase(getEventDataInJobs.rejected, (state, action) => {
                state.jobLoading = false;
            })
            .addCase(usersJobEventUploadLog.pending, (state, action) => {
                state.jobLoading = true;

            })
            .addCase(usersJobEventUploadLog.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.uploadLogsData = action.payload;
            })
            .addCase(usersJobEventUploadLog.rejected, (state, action) => {
                state.jobLoading = false;
            })
            .addCase(jobAllocationById.pending, (state, action) => {
                state.jobLoading = true;
            })
            .addCase(jobAllocationById.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.allocateJobByIdData = action.payload;
            })
            .addCase(jobAllocationById.rejected, (state, action) => {
                state.jobLoading = false;
            })
            .addCase(uploadDataEntryExcel.pending, (state, action) => {
                state.jobLoading = true;
            })
            .addCase(uploadDataEntryExcel.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.uploadExcelDataSuccess = true;
            })
            .addCase(uploadDataEntryExcel.rejected, (state, action) => {
                state.jobLoading = false;
                state.uploadExcelDataError = true;
            })
            .addCase(UpdateJobEVent.pending, (state, action) => {
                state.jobLoading = true;
                state.jobEventUpdateError = false;
                state.jobEventUpdateSuccess = false;
            })
            .addCase(UpdateJobEVent.fulfilled, (state) => {
                state.jobLoading = false;
                state.jobEventUpdateSuccess = true;
            })
            .addCase(UpdateJobEVent.rejected, (state, action) => {
                state.jobLoading = false;
                state.jobEventUpdateError = true;
            })
            .addCase(addJobEvent.pending, (state, action) => {
                state.jobLoading = true;
            })
            .addCase(addJobEvent.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.addJobEventsSuccess = true;
            })
            .addCase(addJobEvent.rejected, (state, action) => {
                state.jobLoading = false;
                state.addJobEventsError = true;

            })
            .addCase(deleteJobEvent.pending, (state, action) => {
                state.jobLoading = true;
            })
            .addCase(deleteJobEvent.fulfilled, (state, action) => {
                state.jobLoading = false;
                state.deleteJobSuccess = true;
            })
            .addCase(deleteJobEvent.rejected, (state, action) => {
                state.jobLoading = false;
                state.deleteJobError = true;
            })


    },
});

// Export actions and selectors
export const { resetSuccessFlag, resetAllocationData } = jobSlice.actions;
export const jobSelector = (state) => state[namespace];
export default jobSlice.reducer;
