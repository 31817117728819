import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    PrepTeamQueueList,
    editPreprocessing,
    createPrepTeamQueue,
    editPrepTeamQueue,
    PrepTeamUserData,
    prepTeamQUeueById,
    allocatePrepDataToUsers,
    allocatePrepDataById,
    uploadExcelPrepData,
    updatePrepData,
    getUploadLogs
} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "prepTeamQueue";

// Async thunk for fetching the Portal customer list
export const getPrepTeamQueue = createAsyncThunk(
    `${namespace}/getPrepTeamQueue`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await PrepTeamQueueList(query, page, limit, sortby, order);
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Portal customer
export const addPrepTeamQueue = createAsyncThunk(
    `${namespace}/addPrepTeamQueue`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await createPrepTeamQueue({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Portal Customer data
export const updatePrepTeamQueue = createAsyncThunk(
    `${namespace}/updatePrepTeamQueue`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await editPrepTeamQueue({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const prepTeamQueueUsersData = createAsyncThunk(
    `${namespace}/prepTeamQueueUsersData`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", userId },
        { rejectWithValue }
    ) => {
        try {

            const data = await PrepTeamUserData({ query, page, limit, sortby, order, userId });
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
export const prepQueueById = createAsyncThunk(
    `${namespace}/prepQueueById`,
    async (
        { editId, assign_user_id },

        { rejectWithValue }
    ) => {
        try {

            const data = await prepTeamQUeueById({ editId, assign_user_id });
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const editPreprocessingData = createAsyncThunk(
    `${namespace}/editPreprocessingData`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editPreprocessing({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


export const addDataAllocation = createAsyncThunk(
    `${namespace}/addDataAllocation`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await allocatePrepDataToUsers({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const prepDataAllocationById = createAsyncThunk(
    `${namespace}/prepDataAllocationById`,
    async (
        { editId },

        { rejectWithValue }
    ) => {
        try {

            const data = await allocatePrepDataById({ editId });
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const addExcelPrepProcess = createAsyncThunk(
    `${namespace}/addExcelPrepProcess`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await uploadExcelPrepData({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
export const updatePrepProcessData = createAsyncThunk(
    `${namespace}/updatePrepProcessData`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await updatePrepData({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const excelUploadData = createAsyncThunk(
    `${namespace}/excelUploadData`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", userId },
        { rejectWithValue }
    ) => {
        try {

            const data = await getUploadLogs({ query, page, limit, sortby, order, userId });
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// User Group Slice
const prepTeamQueueSlice = createSlice({
    name: namespace,
    initialState: {
        prepTeamQueueLoading: false,
        prepTeamQueueListData: [], // Ensure this structure matches the expected format
        prepTeamQueueListError: false,
        addPrepTeamQueueSuccess: false,
        addPrepTeamQueueError: false,
        addPrepTeamQueueErrorMessage: "",
        updatePrepTeamQueueSuccess: false,
        updatePrepTeamQueueError: false,
        updatePrepTeamQueueErrorMessage: "",
        prepTeamQueueUsersListData: [], // Ensure this structure matches the expected format
        prepQueueByIdData: [],
        editProcessDatas: false,
        editProcessDataError: false,
        editProcessDataErrorMessage: "",
        addDataAllocationSuccess: false,
        addDataAllocationError: false,
        addDataAllocationErrorMessage: "",
        allocatePrepQueueByIdData: [],
        addExcelPrepProcessSuccess: false,
        addExcelPrepProcessError: false,
        addExcelPrepProcessErrorMessage: "",
        updatePrepProcessSuccess: false,
        updatePrepProcessError: false,
        updatePrepProcessErrorMessage: "",
        excelUploadDatas:[]
    },

    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },

    extraReducers: (builder) => {
        builder
            // Handle getUserGroupList
            .addCase(getPrepTeamQueue.pending, (state) => {
                state.prepTeamQueueLoading = true;
            })
            .addCase(getPrepTeamQueue.fulfilled, (state, action) => {
                const data = action.payload;
                state.prepTeamQueueListData = data;

                state.prepTeamQueueLoading = false;
            })

            .addCase(getPrepTeamQueue.rejected, (state) => {
                state.prepTeamQueueLoading = false;
            })

            // Handle addCustoer
            .addCase(addPrepTeamQueue.pending, (state) => {
                state.prepTeamQueueLoading = true;
                state.addPrepTeamQueueError = false;
                state.addPrepTeamQueueSuccess = false;
            })
            .addCase(addPrepTeamQueue.fulfilled, (state) => {
                state.prepTeamQueueLoading = false;
                state.addPrepTeamQueueSuccess = true;


            })
            .addCase(addPrepTeamQueue.rejected, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.addCustomerError = true;
                state.addCustomerErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })

            // Handle updateUserGroup
            .addCase(updatePrepTeamQueue.pending, (state) => {
                state.updatePrepTeamQueueError = false;
                state.updatePrepTeamQueueSuccess = false;

            })
            .addCase(updatePrepTeamQueue.fulfilled, (state) => {
                state.updatePrepTeamQueueSuccess = true;
            })
            .addCase(updatePrepTeamQueue.rejected, (state, action) => {
                state.updatePrepTeamQueueError = true;
                state.updatePrepTeamQueueErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })
            .addCase(prepTeamQueueUsersData.pending, (state) => {
                state.prepTeamQueueLoading = true;
            })
            .addCase(prepTeamQueueUsersData.fulfilled, (state, action) => {
                const data = action.payload;
                state.prepTeamQueueUsersListData = data;
                state.prepTeamQueueLoading = false;
            })
            .addCase(prepTeamQueueUsersData.rejected, (state) => {
                state.prepTeamQueueLoading = false;
            })
            .addCase(prepQueueById.pending, (state) => {
                state.prepTeamQueueLoading = true;
            })
            .addCase(prepQueueById.fulfilled, (state, action) => {
                const data = action.payload;
                state.prepQueueByIdData = data;
                state.prepTeamQueueLoading = false;
            })
            .addCase(prepQueueById.rejected, (state) => {
                state.prepTeamQueueLoading = false;
            })
            .addCase(editPreprocessingData.pending, (state) => {
                state.prepTeamQueueLoading = true;

            })
            .addCase(editPreprocessingData.fulfilled, (state) => {
                state.prepTeamQueueLoading = false;
                state.editProcessDatas = true;
            })
            .addCase(editPreprocessingData.rejected, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.editProcessDataError = true;
                state.editProcessDataErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })
            .addCase(addDataAllocation.pending, (state, action) => {
                state.prepTeamQueueLoading = true;
            })
            .addCase(addDataAllocation.fulfilled, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.addDataAllocationSuccess = true;
            })
            .addCase(addDataAllocation.rejected, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.addDataAllocationError = true;
            })
            .addCase(prepDataAllocationById.pending, (state) => {
                state.prepTeamQueueLoading = true;
            })
            .addCase(prepDataAllocationById.fulfilled, (state, action) => {
                const data = action.payload;
                state.allocatePrepQueueByIdData = data;
                state.prepTeamQueueLoading = false;
            })
            .addCase(prepDataAllocationById.rejected, (state) => {
                state.prepTeamQueueLoading = false;
            })
            .addCase(addExcelPrepProcess.pending, (state) => {
                state.prepTeamQueueLoading = true;
            })
            .addCase(addExcelPrepProcess.fulfilled, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.addExcelPrepProcessSuccess = true;
            })
            .addCase(addExcelPrepProcess.rejected, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.addExcelPrepProcessError = true;
                state.addExcelPrepProcessErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })
            .addCase(updatePrepProcessData.pending, (state) => {
                state.prepTeamQueueLoading = true;
            })
            .addCase(updatePrepProcessData.fulfilled, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.updatePrepProcessSuccess = true;
            })
            .addCase(updatePrepProcessData.rejected, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.updatePrepProcessError = true;
                state.updatePrepProcessErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })
            .addCase(excelUploadData.pending, (state, action) => {
                state.prepTeamQueueLoading = true;
            })
            .addCase(excelUploadData.fulfilled, (state, action) => {
                state.prepTeamQueueLoading = false;
                state.excelUploadDatas = action.payload;
            })
            .addCase(excelUploadData.rejected, (state, action) => {
                state.prepTeamQueueLoading = false;
            });
    },
});

// Export actions and selectors
export const { resetSuccessFlag } = prepTeamQueueSlice.actions;
export const prepTeamQueueSelector = (state) => state[namespace];
export default prepTeamQueueSlice.reducer;
