import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// import Dashboard from "./components/dashboard";
import NavBar from "./view/navbar";
const PrivateRoute = () => {
   const isAuthenticated = localStorage.getItem("isAuthenticated");

   if (!isAuthenticated) {
      localStorage.clear();
   }

   if (!isAuthenticated) {
      return <Navigate to="/login" />;
   }

   return (
      <div id="wrapper">
         <div>
            <NavBar />
         </div>
         <div id="main-content">
            <Outlet />
         </div>
      </div>
   );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(PrivateRoute);
