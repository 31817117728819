import { configureStore } from "@reduxjs/toolkit";
import itemCategoriesReducer from "./reducer/itemCategoriesSlice";
import userGroupReducer from "./reducer/userGroupSlice";
import customerReducer from "./reducer/customerSlice";
import loginReducer from "./reducer/login";
import userManagementReducer from "./reducer/userManagementSlice";
import jobsReducer from "./reducer/jobSlice";
import eventValidatorReducer from "./reducer/eventValidationSlice";
import evenReducer from "./reducer/eventSlice";
import workflowReducer from "./reducer/workflowSlice";
import prepTeamQueueReducer from "./reducer/prepQueueSlice";


const store = configureStore({
  reducer: {
    login: loginReducer,
    itemCategories: itemCategoriesReducer,
    userGroups: userGroupReducer,
    customers: customerReducer,
    userManagement: userManagementReducer,
    jobs: jobsReducer,
    eventValidation: eventValidatorReducer,
    events: evenReducer,
    workflow: workflowReducer,
    prepTeamQueue: prepTeamQueueReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
