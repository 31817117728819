import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  tryLogin,
  forgotPassword,
  resetPassword,
  dashboardData,
} from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import { toast } from "react-toastify";
const namespace = "login";
const initialState = {
  loading: "initial",
  forgotloading: "initial",
  resetloading: "initial",
  errorMessage: null,
  loginData: null,
  dashboardDatas: null,
};

export const doLogin = createAsyncThunk(
  `${namespace}/doLogin`,
  async ({ postData }, { rejectWithValue, dispatch }) => {
    try {
      const data = await tryLogin(postData);
      toast.success("Loggedin Successfully!");
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const forgotPwd = createAsyncThunk(
  `${namespace}/forgotPwd`,
  async ({ postData }, { rejectWithValue, dispatch }) => {
    try {
      const data = await forgotPassword(postData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const resetPwd = createAsyncThunk(
  `${namespace}/resetPwd`,
  async ({ postData }, { rejectWithValue, dispatch }) => {
    try {
      const data = await resetPassword(postData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getDashboardData = createAsyncThunk(
  `${namespace}/getDashboardData`,
  async ({ type, id, staff_admin }, { rejectWithValue, dispatch }) => {
    try {
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await dashboardData(type, id, staff_admin);
      return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const loginSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    logOut: () => {
      localStorage.clear();
      window.location.reload(true);
    },
    resetJobStatusResponse: (state) => {
      state.jobStatusResponse = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doLogin.pending, (state) => {
        state.loading = API_STATUS.PENDING;
      })
      .addCase(doLogin.fulfilled, (state, { payload }) => {
        state.loading = API_STATUS.FULFILLED;
        state.loginData = payload?.data;
        localStorage.setItem("user_id", payload?.data?.id);
        localStorage.setItem("authToken", payload?.data?.accessToken);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("username", payload?.data?.username);
        localStorage.setItem("role_id", payload?.data?.roleId);
        localStorage.setItem("role_type", payload?.data?.role);
        localStorage.setItem("groups", payload?.data?.groups);
      })
      .addCase(doLogin.rejected, (state, action) => {
        state.loading = API_STATUS.REJECTED;
        state.errorMessage = action?.payload?.data;
      })
      .addCase(forgotPwd.pending, (state) => {
        state.forgotloading = API_STATUS.PENDING;
      })
      .addCase(forgotPwd.fulfilled, (state, { payload }) => {
        state.forgotloading = API_STATUS.FULFILLED;
      })
      .addCase(forgotPwd.rejected, (state, action) => {
        state.forgotloading = API_STATUS.REJECTED;
        state.errorMessage = action?.payload?.data;
      })
      .addCase(resetPwd.pending, (state) => {
        state.resetloading = API_STATUS.PENDING;
      })
      .addCase(resetPwd.fulfilled, (state, { payload }) => {
        state.resetloading = API_STATUS.FULFILLED;
      })
      .addCase(resetPwd.rejected, (state, action) => {
        state.resetloading = API_STATUS.REJECTED;
        state.errorMessage = action?.payload?.data;
      })
      .addCase(getDashboardData.pending, (state) => {
        state.resetloading = API_STATUS.PENDING;
      })
      .addCase(getDashboardData.fulfilled, (state, { payload }) => {
        state.dashboardDatas = payload?.response;
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.resetloading = API_STATUS.REJECTED;
        state.errorMessage = action?.payload?.data;
      });
  },
});

export const { clearData, logOut, resetJobStatusResponse } = loginSlice.actions;
export const loginSelector = (state) => state.login;
export default loginSlice.reducer;
