export const Leftmenu = () => {
   const userGroupData = localStorage.getItem('groups');
   const roleID = localStorage.getItem("role_id");
   const menus = [
      {
         menu: "Dashboard",
         url: "/dashboard",
         container: "dashboard-container",
         ischild: false,
         icon: "fa fa-fa fa-upload",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "User Groups",
         url: "/user-group",
         container: "user-groups-container",
         ischild: false,
         icon: "fa fa-users",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "User Management",
         url: "/user-management",
         container: "user-management-container",
         ischild: false,
         icon: "fa fa-address-book",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Customers",
         url: "/customers",
         container: "customers-container",
         ischild: false,
         icon: "fa fa-handshake-o",
         child: [{ menu: "", url: "" }],
      },

      {
         menu: "Events",
         url: "/events",
         container: "events-container",
         ischild: false,
         icon: "fa fa-calendar",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Jobs",
         url: "/jobs",
         container: "jobs-container",
         ischild: false,
         icon: "fa fa-briefcase",
         child: [{ menu: "", url: "" }],
      },




      {
         menu: "Preprocessing Logs",
         url: "/upload-log",
         container: "file-uploads-container",
         ischild: false,
         icon: "fa fa-upload",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Job Event Logs",
         url: "/job-event-log",
         container: "file-uploads-container",
         ischild: false,
         icon: "fa fa-upload",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Workflow",
         url: "/workflow",
         container: "workflow-container",
         ischild: false,
         icon: "fa fa-sitemap",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Pre Processing",
         url: "/prep-queue",
         container: "prep-queue-container",
         ischild: false,
         icon: "fa fa-database",
         child: [{ menu: "", url: "" }],
      },

      {
         menu: "Reports",
         url: "/reports",
         container: "reports-container",
         ischild: false,
         icon: "fa fa-print",
         child: [{ menu: "", url: "" }],
      },

      {
         menu: "Barcode Generator",
         url: "/barcode",
         container: "barcode-container",
         ischild: false,
         icon: "fa fa-barcode",
         child: [{ menu: "", url: "" }],
      },
   ];

   let filteredMenus = [];

   if (roleID === "1" || roleID === "2") {
      filteredMenus = menus.filter(menu => menu.menu !== "Preprocessing Logs" && menu.menu !== "Job Event Logs");
   } else if (roleID === "3") {
      // Start with Dashboard
      filteredMenus = [menus.find(menu => menu.menu === "Dashboard")];

      if (userGroupData.split(',').includes('1')) {
         // Add Pre Processing if user belongs to group 1
         const preProcessingMenu = menus.find(menu => menu.menu === "Pre Processing");
         if (preProcessingMenu) filteredMenus.push(preProcessingMenu);
      }

      // Add Jobs
      filteredMenus.push(menus.find(menu => menu.menu === "Jobs"));

      if (userGroupData.split(',').includes('1')) {
         // Add Preprocessing Logs if user belongs to group 1
         const uploadLogsMenu = menus.find(menu => menu.menu === "Preprocessing Logs");
         if (uploadLogsMenu) filteredMenus.push(uploadLogsMenu);

         // Add Job Event Logs if user belongs to group 1
         const jobEventLogsMenu = menus.find(menu => menu.menu === "Job Event Logs");
         if (jobEventLogsMenu) filteredMenus.push(jobEventLogsMenu);
      }
   }

   return filteredMenus;
};
