import { useState } from "react";
import { EditOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Select, Space, Tooltip, Form, Input, Modal, Tabs, Table } from "antd";

import { Link } from "react-router-dom";
import PageHeader from "../../view/pageheader";
import TableComponent from "../CustomComponents/TableComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  resetAllocationData, resetSuccessFlag, deleteJob, getJobsList, updateJobStatus, getUsersJobsList, allocateJobUsers, addDataAllocation,
  getEventDataInJobs, jobAllocationById,
  addJobEvent,
  deleteJobEvent,
} from "../../store/reducer/jobSlice";
import { useEffect } from "react";
import SwalComponent from "../CustomComponents/SwalComponent";
import Swal from "sweetalert2";
import SearchComponent from "../CustomComponents/SearchComponent";
// import { resetSuccessFlag,resetAllocationData } from "../../store/reducer/jobSlice";
import moment from 'moment-timezone';


const AllocationModal = ({ record, totalVolume, preprocessID, userData, onCancel }) => {
  const dispatch = useDispatch();
  const { allocateJobByIdData } = useSelector((state) => state.jobs);
  const [form] = Form.useForm();
  const [allocated, setAllocated] = useState(0);
  const [originalAllocations, setOriginalAllocations] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isPrevAllocatedModalVisible, setIsPrevAllocatedModalVisible] = useState(false);
  const [prevAllocations, setPrevAllocations] = useState([]);

  // Fetch existing allocations when modal opens
  useEffect(() => {
    if (record?.id) {
      dispatch(jobAllocationById({ editId: record.id }));
      
    }
  }, [dispatch, record]);

  // Populate form fields and store previous allocations
  useEffect(() => {
    if (allocateJobByIdData && allocateJobByIdData.length > 0) {
      const formattedData = allocateJobByIdData.map((item) => ({
        user: { label: item.user_role?.user_name, value: item.user_role?.id },
        data_count: item.data_count,
        timestamp: item.created_at,
      }));

      setOriginalAllocations(formattedData);
      setPrevAllocations(formattedData);

      const totalAllocated = formattedData.reduce((sum, alloc) => sum + (parseInt(alloc.data_count, 10) || 0), 0);
      setAllocated(totalAllocated);
    }
  }, [allocateJobByIdData, record, form]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      const totalAssigned = values.allocations.reduce((sum, { data_count }) => sum + (parseInt(data_count, 10) || 0), 0);

      if (totalAssigned > totalVolume) {
        toast.error("Total allocation exceeds available boxes!");
        return;
      }

      const newOrUpdatedAllocations = values.allocations.filter((allocation) => {
        const existingAllocation = originalAllocations.find(
          (orig) => orig.user.value === allocation.user.value
        );
        return !existingAllocation || existingAllocation.data_count !== allocation.data_count;
      });

      if (newOrUpdatedAllocations.length === 0) {
        toast.info("No changes detected in allocations.");
        return;
      }

      const payload = newOrUpdatedAllocations.map((allocation) => ({
        user_id: allocation.user.value,
        spec_id: record?.id,
        spec: record?.spec_id,
        allocatedPreprocessId: preprocessID.slice(0, allocation?.data_count || 0),
        data_count: allocation?.data_count,
      }));
      dispatch(addDataAllocation({ postData: payload }));
      // dispatch(addJobEvent({ updateID: record.id,postData: payload }));
      toast.success("Allocation updated successfully");
      form.resetFields();
      onCancel();
    }).catch(() => {
      toast.error("Please fix the errors before submitting.");
    });
  };

  const handleCancel = () => {
    form.resetFields(); // Reset form fields properly
    setIsModalVisible(false);
    setIsPrevAllocatedModalVisible(false);
    setOriginalAllocations([]); // Clear stored original allocations
    setPrevAllocations([]); // Clear previous allocations
    setAllocated(0); // Reset allocated count
    dispatch(resetAllocationData()); // Dispatch action to reset allocation data in Redux store
    onCancel(); // Close the modal properly
  };

  const remainingBalance = totalVolume - allocated;

  return (
    <>
      {/* Allocation Modal */}
      <Modal
        title={`Total No of Boxes: ${allocated} / ${totalVolume}`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Button type="primary" onClick={() => setIsPrevAllocatedModalVisible(true)} style={{ marginBottom: 16 }}>
          Allocation History
        </Button>

        <Form form={form} layout="vertical" >
          <Form.List name="allocations">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, "user"]}
                      fieldKey={[fieldKey, "user"]}
                      rules={[{ required: true, message: "Please select a user!" }]}
                    >
                      <Select
                        placeholder="Select a user"
                        style={{ width: 150 }}
                        labelInValue
                        options={userData?.map((user) => ({ label: user.user_name, value: user.id })) || []}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "data_count"]}
                      fieldKey={[fieldKey, "data_count"]}
                      rules={[
                        { required: true, message: "Enter Number" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || isNaN(value)) {
                              return Promise.reject(new Error("Please enter a valid number."));
                            }
                            const currentTotal = (getFieldValue(["allocations"]) || []).reduce(
                              (sum, allocation) => sum + (parseInt(allocation?.data_count) || 0),
                              0,);
                            if (currentTotal > remainingBalance) {
                              return Promise.reject(new Error("Total allocation exceeds available boxes!"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input type="number" placeholder="Min" style={{ width: 80 }} />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      if (allocated < totalVolume) add();
                      else toast.error("Total allocation limit reached!");
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add User
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>

      {/* Previously Allocated Users Modal */}
      <Modal
        title="User Allocation History"
        open={isPrevAllocatedModalVisible}
        onCancel={() => setIsPrevAllocatedModalVisible(false)}
        footer={null}
        width={1000}
      >
        <Table
          dataSource={prevAllocations}
          columns={[
            {
              title: "User",
              dataIndex: "user",
              key: "user",
              render: (user) => user.label,
            },
            {
              title: "Allocated Boxes",
              dataIndex: "data_count",
              key: "data_count",
            },
            {
              title: "Allocated At",
              dataIndex: "timestamp",
              key: "timestamp",
              render: (timestamp) => {
                return moment(timestamp).tz('America/Chicago').format('MM-DD-YYYY hh:mm A')
              },
            },
          ]}
          rowKey={(record) => record.user.value}
        />
      </Modal>
    </>
  );
};


const Jobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobListData, deleteJobSuccess, jobStatusSuccess, userJosData, userDataINJObs, getEventDataInJobsResponse } = useSelector((state) => state.jobs);
  console.log(userDataINJObs, "userDataINJObs22");




  const role_id = localStorage.getItem("role_id");
  const userId = localStorage.getItem("user_id");
  const { count: totalCount, data: tableData } = role_id == 3 ? userJosData : jobListData;
  console.log(tableData, "tableData222");


  const [selectedRecord, setSelectedRecord] = useState(null);
  function getData(payload = {}) {
    if (role_id == 3) {
      // Add userId to payload for role_id == 3
      dispatch(getUsersJobsList({ ...payload, userId }));
    } else {
      // dispatch(allocateJobUsers(payload))
      // dispatch(getEventDataInJobs(payload))
      dispatch(getJobsList(payload));
    }
  }
  useEffect(() => {
    getData({})
    if (deleteJobSuccess) {


      // toast.success("Job Deleted Successfully");
      dispatch(resetSuccessFlag({ flag: "deleteJobSuccess" }));

    }

  }, [deleteJobSuccess, dispatch])

  useEffect(() => {
    if (jobStatusSuccess) {
      toast.success("Job status updated successfully");
      getData();
      dispatch(resetSuccessFlag({ flag: "jobStatusSuccess" }))
    }

  }, [jobStatusSuccess])
  const handleStatusChange = (record, updatedStatus) => {


    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to change the Job status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const postData = { status: updatedStatus, spec: record?.spec_id, volume: record?.volume }
        dispatch(updateJobStatus({
          postData: { status: updatedStatus, spec: record?.spec_id, volume: record?.volume },
          updateID: record.id
        }));
        // if (updatedStatus == 3) {
        //   dispatch(addJobEvent({ updateID: record.id }))
        // }
        if (updatedStatus == 2) {
          dispatch(deleteJobEvent({ updateID: record.id }))

        }


      }
    });
  }
  const handleAllocateClick = (record) => {
    setSelectedRecord(record);
    dispatch(allocateJobUsers({ jobId: record?.id })); // Dispatch the action to fetch user data
    dispatch(getEventDataInJobs({ jobId: record?.id })); // Dispatch the action to fetch event data
  };

  const columns = [

    {
      title: "Job Name",
      dataIndex: "job_name",
      key: "job_name",
      align: "center",
      sorter: (a, b) => a.job_name.localeCompare(b.job_name),

    },
    {
      title: "Customer",
      dataIndex: 'customer',
      key: "customer",
      align: "center",
    
      render: data => (
        data?.customername ? data?.customername : "N/A"
      )
    },
    {
      title: "To",
      dataIndex: "user_role",
      key: "to",
      align: "center",
      
      render: data => (
        data?.user_name ? data?.user_name : "N/A"


      )
    },
    {
      title: "Pickup Date",
      dataIndex: "pickup_date",
      key: "created_by",
      align: "center",
      sorter: true,
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-GB"); // Formats date as dd/mm/yyyy
        return formattedDate;
      }
    },
    {
      title: "Spec ID",
      dataIndex: "spec_id",
      key: "spec_id",
      align: "center",
      sorter: (a, b) => a.spec_id-(b.spec_id),
    },
    // {
    //   title: "Events",
    //   dataIndex: "Events",
    //   key: "Events",
    //   align: "center",
    //   sorter: true,
    // },
    {
      title: "Allocation",
      key: "allocation",
      align: "center",
      render: (_, record) =>
        record?.status !== 2 ? ( // Check if status is 2
          <Button type="primary" onClick={() => handleAllocateClick(record)}>
            Allocate
          </Button>
        ) : null // Return null if the condition is false
    }
    ,


    {
      title: "Status",
      key: "status",
      align: "center",
      render: (record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: record.status === 1 ? 'blue' : 'inherit', }}>
          <Select
            value={record.status}
            onChange={(value) => handleStatusChange(record, value)}

          >
            <Select.Option value={1} style={{ color: 'blue' }}>Awaiting Review</Select.Option>
            <Select.Option value={2} style={{ color: 'orange' }}>Yet to Start</Select.Option>
            <Select.Option value={3} style={{ color: 'green' }}>In Progress</Select.Option>
            <Select.Option value={4} style={{ color: 'gray' }}>Completed</Select.Option>
            <Select.Option value={5} style={{ color: 'red' }}>Return to Customer</Select.Option>
          </Select>
        </div>


      )
    },
    {
      title: role_id == 1 || role_id == 2 ? "Action" : "",
      key: "Action",
      align: "center",
      render: data => {
        if (role_id == 1 || role_id == 2) {
          return (
            <Space>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  className="border-0"
                  icon={
                    <EditOutlined
                      onClick={() => navigate(`/jobs/edit/${btoa(data?.id)}`)}
                      style={{ color: "blue" }}
                    />
                  }
                />
              </Tooltip>
              <Tooltip title="Delete">
                <SwalComponent
                  message="Are you sure you want to delete the Job?"
                  onClickDispatch={deleteJob}
                  id={data?.id}
                />
              </Tooltip>
            </Space>
          );
        }
        return null; // Return null if the condition is not met
      }
    }

  ];
  const UserColumns = [

    {


      title: "Job Name",
      // dataIndex: "job_name",
      // key: "job_name",
      render: (_, record) => record?.spec_details?.job_name || "N/A",
      align: "center",
      // sorter: (a, b) => a.job_name-(b.job_name),

    },



    {
      title: "Spec",
      dataIndex: "spec_id",
      key: "spec_id",
      render: (_, record) => record.spec_details?.spec_id,
      align: "center",
      // sorter: (a, b) => a.spec_id-(b.spec_id),
    },
    {
      title: "Workflow ",
      dataIndex: 'workflow_name',
      key: "workflow_name",
      render: (_, record) => record.spec_details?.spec[0]?.workflow_name,
      align: "center",
      // sorter: (a, b) => a.workflow_name-(b.workflow_name),
    },
    {
      title: role_id == 3 ? "Action " : "",
      key: "Action",
      align: "center",
      render: data => {
        if (role_id == 3) {
          return (
            <Space>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  className="border-0"
                  icon={
                    <PlusOutlined
                      onClick={() => navigate(`/jobs/jobs_event/${btoa(data?.spec_details?.id)}?job_name=${btoa(data?.spec_details?.job_name)}&workflow_name=${btoa(data?.spec_details?.spec[0]?.workflow_name)}`)}
                      style={{ color: "blue" }}
                    />

                  }
                />
              </Tooltip>

            </Space>
          );
        }
        return null; // Return null if the condition is not met
      }
    }


  ];
  return (
    <div>
      <PageHeader HeaderText="Jobs" Breadcrumb={[{ name: "Jobs" }]} backButtonUrl="" />
      <Card
        title="Jobs List"
        extra={
          <>
            <Space size={"middle"} className="my-3">

              <SearchComponent
                placeHolder="Search Job Name"
                onChange={e => {
                  const payloadData = {
                    query: e.target.value,
                  };
                  getData(payloadData);
                }} />


            </Space>
            {role_id != 3 && (<>
              <Link to="/jobs/add">
                <Button className="primary-btn ml-2" shape="round">
                  Add
                </Button>
              </Link>
            </>)}

          </>
        }
      >
        <TableComponent columns={role_id == 3 ? UserColumns : columns} dataSource={tableData} size={"small"} />
      </Card>
      {selectedRecord && (
        <AllocationModal style={{ width: '700px' }}
          record={selectedRecord}
          userData={userDataINJObs?.datas?.data}
          totalVolume={userDataINJObs?.datas?.volume}
          preprocessID={userDataINJObs?.datas?.preprocess_id}
          eventData={getEventDataInJobsResponse}
          onCancel={() => {
            setSelectedRecord(null); // Ensure the modal completely resets
          }}
        />
      )}
    </div>
  );
};

export default Jobs;
