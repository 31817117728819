import React, { useEffect } from 'react'
import TableComponent from "../CustomComponents/TableComponent";
import PageHeader from '../../view/pageheader';
import { Card } from 'antd';
import { usersJobEventUploadLog } from '../../store/reducer/jobSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NODELOCALASSETURL } from '../../services/config'
const JobEventUploadLogs = () => {
    const dispatch = useDispatch();
    const { uploadLogsData } = useSelector((state) => state.jobs);
    const { data: tableData } = uploadLogsData || {}
    console.log(tableData, "tableData1111");

    const userId = localStorage.getItem("user_id");
    function getData(payload = {}) {
        dispatch(usersJobEventUploadLog({ ...payload, userId }));
    }
    useEffect(() => {
        getData({});
    }, [dispatch]);

    const columns = [
        {
            title: "File Name",
            dataIndex: "file_name",
            key: "file_name",
            align: "center",
            render: (text, record) => (
                <Link to={`${NODELOCALASSETURL}${record.file_path}`} target="_blank" rel="noopener noreferrer">
                    {text}
                </Link>
            ),
            sorter: true,

        },
        {
            title: "Total Records",
            dataIndex: "total_records",
            key: "total_records",
            align: "center",
            sorter: true,

        },
        {
            title: "Rejected Records",
            dataIndex: "rejected_records",
            key: "rejected_records",
            align: "center",
            sorter: true,
        },
        {
            title: "Accepted Records",
            key: "Accepted_records",
            align: "center",
            render: (text, record) => record.total_records - record.rejected_records,
            sorter: true,
        }



    ];

    return (
        <>
            <PageHeader HeaderText="Job Event Upload Logs" backButtonUrl="" />
            <Card>
                <TableComponent columns={columns} dataSource={tableData} size={"small"} />
            </Card>
        </>
    );
};

export default JobEventUploadLogs