import React from "react";
import { Controller } from "react-hook-form";
import { Input, Form } from "antd";

const EmailInput = ({ name, control, label, placeholder, errors, required,disabled }) => (
   <Form.Item
      layout="vertical"
      label={
         <span>
            {label}
            {required && (
               <span className="ms-1" style={{ color: "red" }}>
                  *
               </span>
            )}
         </span>
      }
      validateStatus={errors[name] ? "error" : ""}
      help={errors[name]?.message}
   >
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <Input
               {...field}
               type="email"
               placeholder={placeholder}
               onChange={e => field.onChange(e)}
               disabled={disabled}
               style={{
                  color: disabled ? "black" : "inherit", // Gray color for disabled text
                  backgroundColor: disabled ? "#f5f5f5" : "inherit", // Optional: light gray background for disabled
               }}
               //    style={{ marginTop: "8px" }}
            />
         )}
      />
   </Form.Item>
);

export default EmailInput;
