import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Flex, Modal, Row, Space, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PageHeader from "../../view/pageheader";
import SelectInput from "../CustomComponents/SelectInput";
import TableComponent from "../CustomComponents/TableComponent";
import TextInput from "../CustomComponents/TextInput";
import { useDispatch, useSelector } from "react-redux";
import SwalComponent from "../CustomComponents/SwalComponent";
import {
  addUserGroup,
  deleteUserGroup,
  updateUserGroup,
  getUserGroupList,
  resetSuccessFlag,
  updateGroupStatus
} from "../../store/reducer/userGroupSlice";
import { toast } from "react-toastify";
import SearchComponent from "../CustomComponents/SearchComponent";



const UserGroups = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState('add')
  // Selectors
  const {
    userGroupListData,

    addUserGroupSuccess,
    addUserGroupError,
    addUserGroupErrorMessage,
    updateUserGroupSuccess,
    deleteUserGroupSuccess,
    groupStatusSuccess,
    groupStatusError,
    groupStatusErrorMessage

  } = useSelector((state) => state.userGroups);

  const [isAddModal, setIsAddModal] = useState(false);
  const inputAutoFocusRef = useRef(null);
  const [editId, setEditId] = useState(null);

  const { count: totalCount, data: tableData } = userGroupListData;
 
  function getData(payload = {}) {
    dispatch(getUserGroupList(payload));
  }



  const [groupSchema] = useState({
    user_group_name: "",
    status: 1,
    // created_by: "Admin"
  });
  const validationRules = yup.object().shape({
    user_group_name: yup.string().trim().required("Group name is required"),
    status: yup
      .number()
      .typeError("Please select status")
      .required("Status is required"),
    // created_by: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationRules),

    mode: "onChange", // Enable live validation
  });

  useEffect(() => {
    getData({});




    if (addUserGroupSuccess) {

      toast.dismiss();
      toast.success("User group added successfully");
      setIsAddModal(false);
      reset({ ...groupSchema });
      dispatch(resetSuccessFlag({ flag: "addUserGroupSuccess" }))
    }
    if (addUserGroupError) {
      toast.error(addUserGroupErrorMessage);
      dispatch(resetSuccessFlag({ flag: "addUserGroupError" }))
    }
    if (updateUserGroupSuccess) {
      toast.success("User group updated successfully");
      setIsAddModal(false);
      reset({ ...groupSchema });
      dispatch(resetSuccessFlag({ flag: "updateUserGroupSuccess" }))

    }
    if (deleteUserGroupSuccess) {
      toast.success("User group deleted successfully");
      setIsAddModal(false);
      reset({ ...groupSchema });
      dispatch(resetSuccessFlag({ flag: "deleteUserGroupSuccess" }))

    }
    if (groupStatusSuccess) {
      toast.success("User group status updated successfully");
      setIsAddModal(false);
      reset({ ...groupSchema });
      dispatch(resetSuccessFlag({ flag: "groupStatusSuccess" }))

    }
    if (groupStatusError) {
      toast.error(groupStatusErrorMessage);
      setIsAddModal(false);
      reset({...groupSchema });
      dispatch(resetSuccessFlag({ flag: "groupStatusError" }))
      
    }
  }, [addUserGroupSuccess, updateUserGroupSuccess, addUserGroupError,groupStatusError,groupStatusErrorMessage, dispatch, reset, groupSchema, deleteUserGroupSuccess,
    groupStatusSuccess
  ]);
  const [status] = useState([
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Inactive",
      value: 0,
    },
  ]);

  useEffect(() => {
    if (isAddModal && inputAutoFocusRef.current) {
      setTimeout(() => {
        inputAutoFocusRef.current.focus();
      }, 300);
    }
  }, [isAddModal]);



  const columns = [

    {
      title: "Group Name",
      dataIndex: "user_group_name",
      key: "user_group_name",
      align: "center",
      sorter: (a, b) => a.user_group_name.localeCompare(b.user_group_name),
     
    },

    {
      title: "Status",
      key: "status",
      align: "center",
      render: (record) => 
        record.id !== 1 && (
          <SwalComponent
            message="Are you sure you want to change the Group status?"
            onClickDispatch={updateGroupStatus}
            id={record.id}
            status={true}
            statusValue={record.status}
            postData={{ status: record.status === 1 ? 0 : 1 }}
          />
        ),
      sorter: (a, b) => a.status - b.status,
      
    },
    {
      title: "Action",
      key: "Action",
      align: "center",
      render: (data) => (
        <Space>
         {
          data?.id!=1&&(
            <>
             <Tooltip title="Edit">
            <Button
              shape="circle"
              className="border-0 "
              onChange={(e) => setType('edit')}
              icon={<EditOutlined style={{ color: "blue" }} />}
              onClick={() => {
                setType("edit");
                setIsAddModal(true);
                setEditId(data.id);
                reset({ ...data });
              }}
            />
          </Tooltip>
            </>
          )
         }
        </Space>
      ),
    },
  ];

  const onSubmit = (data) => {


    if (type === "add") {
      const payload = {
        postData: data,
      };

      dispatch(addUserGroup(payload)); // Dispatch add action
    } else if (type == "edit") {
      let { user_group_name, role_id, status, id } = data;

      const payload = {
        postData: {
          user_group_name,
          role_id,
          status,
          id
        },
        updateID: editId, // Use the editId for identifying the record to update
      };

      dispatch(updateUserGroup(payload)); // Dispatch update action
    }
  };

  const onModalClose = () => {
    reset({ ...groupSchema });
    setIsAddModal(false);
  };

  const onModalOpen = () => {
    reset({ ...groupSchema });
    setIsAddModal(true);
  };

  return (
    <div>

      <PageHeader
        HeaderText="User Groups"
        Breadcrumb={[{ name: "User Groups" }]}
        backButtonUrl=""
      />
      <Card
        title="Group List"
        extra={
          <>
            <Space size={"middle"} className="my-3">
              <SearchComponent
            
               placeHolder="Search Group Name"
               onChange={e => {

                const payloadData = {
               
                 query: e.target.value,
               
                 };
               
                 getData(payloadData);
               
                 }} />

            </Space>

            <Button className="primary-btn ml-2" shape="round" onClick={() => {
              setType("add");
              onModalOpen(); // Open modal for Add
            }}>
              Add
            </Button>

            <Modal
              title={type === "add" ? "Add User Group" : "Edit User Group"}
              centered
              className="w-50"
              open={isAddModal}
              onOk={handleSubmit(onSubmit)}
              onCancel={onModalClose}
              okButtonProps={{
                className: "primary-btn",
                shape: "round",
                icon: <FileDoneOutlined />,
              }}
              okText={type === "add" ? "Save" : "Update"}
              cancelButtonProps={{ shape: "round", icon: <CloseOutlined /> }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={[10, 0]} wrap className="p-2">
                  <Col span={12}>
                    <TextInput
                      ref={inputAutoFocusRef}
                      name="user_group_name"
                      control={control}
                      label="Group Name"
                      placeholder="Enter customer name"
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectInput
                      name="status"
                      control={control}
                      label="Status"

                      selectDefaultFirstOption={true}
                      placeholder="Select Status"
                      options={status}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                </Row>
              </form>
            </Modal>
          </>
        }
      >
       
          <Flex justify="start"><div className="w-75">
        <TableComponent
          columns={columns}
          dataSource={tableData}
          TotalDataCount={totalCount}
          onChangeDispatch={getData}
          size={"small"}
        /></div></Flex>
      </Card>
    </div>
  );
};

export default UserGroups;
