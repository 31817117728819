import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  userManagementList,
  userManagementDelete,
  createUserManagement,
  editUserManagement,
  updateUserStatus,
} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "userManagement";

// Async thunk for fetching the Portal getUserManagementList
export const getUserManagementList = createAsyncThunk(
  `${namespace}/getUserManagementList`,
  async (
    { query = "", page = 0, limit = 0, sortby = "", order = "" },
    { rejectWithValue }
  ) => {
    try {
      const data = await userManagementList(query, page, limit, sortby, order);
      return JSON.parse(
        EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
      );
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for adding a Portal User group
export const addUserManagement = createAsyncThunk(
  `${namespace}/addUserManagement`,
  async ({ postData }, { rejectWithValue }) => {
    try {
      const payload = EncryptDecrypt.encryptdata(postData, secretKey);
      const data = await createUserManagement({ payload });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for updating Portal User group data
export const updateUserManagement = createAsyncThunk(
  `${namespace}/updateUserManagement`,
  async ({ postData, updateID }, { rejectWithValue }) => {

    try {
      const payload = EncryptDecrypt.encryptdata(postData, secretKey);
      const data = await editUserManagement({ payload }, updateID);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for deleting a Portal User group
export const deleteUserManagement = createAsyncThunk(
  `${namespace}/deleteUserManagement`,
  async ({ deleteID }, { rejectWithValue }) => {
    try {
      const data = await userManagementDelete(deleteID);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


// User status update
export const userUpdateStatus = createAsyncThunk(
  `${namespace}/UpdateStatus`,
  async ({ postData, updateID }, { rejectWithValue }) => {
    try {
      let payload = EncryptDecrypt.encryptdata(postData, secretKey);
      const data = await updateUserStatus({ payload }, updateID);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// User Group Slice
const userManagementSlice = createSlice({
  name: namespace,
  initialState: {
    userManagementLoading: false,
    userManagementListData: [], // Ensure this structure matches the expected format
    userManagementListError: false,
    addUserManagementSuccess: false,
    addUserManagementError: false,
    addUserManagementErrorMessage: "",
    updateUserManagementSuccess: false,
    updateUserManagementError: false,
    updateUserManagementErrorMessage: "",
    deleteUserManagementLoading: false,
    deleteUserManagementSuccess: false,
    deleteUserManagementError: false,
    deleteUserManagementErrorMessage: "",
    userStatusSuccess: false,
    userStatusError: false,
    userStatusErrorMessage: "",
  },

  reducers: {
    resetSuccessFlag: (state, action) => {
      const { flag } = action.payload;
      state[flag] = false;
    },
  },

  extraReducers: (builder) => {
    builder
      // Handle getUserGroupList
      .addCase(getUserManagementList.pending, (state) => {
        state.userManagementLoading = true;
      })
      .addCase(getUserManagementList.fulfilled, (state, action) => {
        const data = action.payload;
        state.userManagementListData = data;
        state.userManagementLoading = false;
      })
      .addCase(getUserManagementList.rejected, (state) => {
        state.userManagementLoading = false;
      })
      .addCase(addUserManagement.pending, (state) => {
        state.userManagementLoading = true;
        state.addUserManagementError = false;
        state.addUserManagementSuccess = false;
      })
      .addCase(addUserManagement.fulfilled, (state) => {
        state.addUserManagementError = false;
        state.addUserManagementSuccess = true;
      })
      .addCase(addUserManagement.rejected, (state, action) => {
        state.addUserManagementError = true;
        state.addUserManagementErrorMessage =
          action.payload?.data?.message || "An error occurred";
      })

      // Handle updateUserGroup
      .addCase(updateUserManagement.pending, (state) => {
        state.updateUserManagementSuccess = false;
        state.updateUserManagementError = false;
        state.updateUserManagementErrorMessage = "";
      })
      .addCase(updateUserManagement.fulfilled, (state) => {
        state.updateUserManagementSuccess = true;
      })
      .addCase(updateUserManagement.rejected, (state, action) => {
        state.updateUserManagementError = true;
        state.updateUserManagementErrorMessage =
          action.payload?.data?.message || "An error occurred";
      })
      .addCase(deleteUserManagement.pending, (state) => {
        state.userManagementLoading = true;
        state.deleteUserManagementError = false;
        state.deleteUserManagementSuccess = false;
      })
      .addCase(deleteUserManagement.fulfilled, (state) => {
        state.deleteUserManagementError = false;
        state.deleteUserManagementSuccess = true;
      })
      .addCase(deleteUserManagement.rejected, (state, action) => {
        state.userManagementLoading = false;
        state.deleteUserManagementError = true;
        state.deleteUserManagementErrorMessage =
          action.payload?.data?.message || "An error occurred";
      })
      .addCase(userUpdateStatus.pending, (state) => {
        state.userManagementLoading = true;
        state.userStatusError = false;
        state.userStatusSuccess = false;
      })
      .addCase(userUpdateStatus.fulfilled, (state) => {
        state.userStatusError = false;
        state.userStatusSuccess = true;
      })
      .addCase(userUpdateStatus.rejected, (state, action) => {

        state.userManagementLoading = false;
        state.userStatusError = true;
        console.log(state.userStatusError, "jjjjj");

        state.userStatusErrorMessage =
          action.payload?.data?.message || "An error occurred";
      });

  }
});

// Export actions and selectors
export const { resetSuccessFlag } = userManagementSlice.actions;
export const userGroupSelector = (state) => state[namespace];
export default userManagementSlice.reducer;
