import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import DashBoard from "../components/DashBoard";
import Jobs from "../components/Jobs/Jobs";
import JobsEvents from "../components/Jobs/JobsEvent";
import PrepTeamQueue from "../components/PrepTeamQUeue/PrepTeamQueue";
import AddPreprocessing from "../components/PrepTeamQUeue/AddPreProcessing";
import UserUploadLogs from "../components/UploadLogs/UserUploadLogs";
import JobEventUploadLogs from "../components/UploadLogs/JobEventUploadLogs";
import NotFound from "../components/404/NotFound";

const UserRoutes = () => {
   return (
      <Routes>
         <Route path="/" element={<Outlet />}>
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="jobs" element={<Jobs />} />
            <Route path="jobs/jobs_event/:id" element={<JobsEvents />} />
            <Route path="/prep-queue" element={<PrepTeamQueue />} />
            <Route path="/add-preprocess/:id" element={<AddPreprocessing />} />
            <Route path="/upload-log" element={<UserUploadLogs />} />
            <Route path="/job-event-log" element={<JobEventUploadLogs />} />
            <Route path="*" element={<NotFound />} />

           
         </Route>
      </Routes>
   );
};

export default UserRoutes;
