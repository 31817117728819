import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { itemCategoriesList, createItemCategories, editItemCategories, getItemCategoriesDataByID, updateItemCategoriesStatus, itemCategoriesDelete } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "itemCategories";

// Async thunk for fetching the Portal User list
export const getItemCategoriesList = createAsyncThunk(
    `${namespace}/getItemCategoriesList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", id = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await itemCategoriesList(query, page, limit, sortby, order, id);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Portal User
export const addItemCategories = createAsyncThunk(
    `${namespace}/addItemCategories`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createItemCategories({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Portal User data
export const updateItemCategories = createAsyncThunk(
    `${namespace}/UpdateItemCategories`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editItemCategories({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deleteItemCategories = createAsyncThunk(
    `${namespace}/itemCategoriesDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            const data = await itemCategoriesDelete(deleteID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


const itemCategories = createSlice({
    name: namespace,
    initialState: {
        itemCategoriesListData: [],
        itemCategoriesListLoading: false,
        addItemCategoriesLoading: false,
        addItemCategoriesSuccess: false,
        addItemCategoriesError: false,
        addItemCategoriesErrorMessage: "",
        updateItemCategoriesSuccess: false,
        updateItemCategoriesError: false,
        updateItemCategoriesErrorMessage: "",
        deleteItemCategoriesLoading: false,
        deleteItemCategoriesSuccess: false,
        deleteItemCategoriesError: false,
        deleteItemCategoriesErrorMessage: "",
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getItemCategoriesList
            .addCase(getItemCategoriesList.pending, (state) => {
                state.itemCategoriesListLoading = true;
                state.addItemCategoriesLoading = false;
                state.addItemCategoriesSuccess = false;
                state.addItemCategoriesError = false;
                state.addItemCategoriesErrorMessage = "";
                state.updateItemCategoriesSuccess = false;
                state.updateItemCategoriesError = false;
                state.updateItemCategoriesErrorMessage = "";
                state.deleteItemCategoriesLoading = false;
                state.deleteItemCategoriesSuccess = false;
                state.deleteItemCategoriesError = false;
                state.deleteItemCategoriesErrorMessage = "";
            })
            .addCase(getItemCategoriesList.fulfilled, (state, action) => {
                const data = action.payload;
                state.itemCategoriesListData = data;
                state.itemCategoriesListLoading = false;
            })
            .addCase(getItemCategoriesList.rejected, (state) => {
                state.itemCategoriesListLoading = false;
            })

            // Handle add Item Categories
            .addCase(addItemCategories.pending, (state) => {
                state.addItemCategoriesLoading = true;
                state.addItemCategoriesError = false;
                state.addItemCategoriesSuccess = false;
            })
            .addCase(addItemCategories.fulfilled, (state, action) => {
                state.addItemCategoriesLoading = false;
                state.addItemCategoriesSuccess = true;
            })
            .addCase(addItemCategories.rejected, (state, action) => {
                const payload = action.payload.data;
                state.addItemCategoriesLoading = false;
                state.addItemCategoriesError = true;
                state.addItemCategoriesErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;
            })

            // Handle update Item Categories
            .addCase(updateItemCategories.pending, (state) => {
                state.updateItemCategoriesSuccess = false;
                state.updateItemCategoriesError = false;
                state.updateItemCategoriesErrorMessage = "";
            })
            .addCase(updateItemCategories.fulfilled, (state, action) => {
                state.updateItemCategoriesSuccess = true;
            })
            .addCase(updateItemCategories.rejected, (state, action) => {
                const payload = action.payload.data;
                state.updateItemCategoriesError = true;
                state.updateItemCategoriesErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;
            })
            .addCase(deleteItemCategories.pending, state => {
                state.deleteItemCategoriesLoading = true;
                state.deleteItemCategoriesError = false;
                state.deleteItemCategoriesSuccess = false;
                state.deleteItemCategoriesErrorMessage = "";
            })
            .addCase(deleteItemCategories.fulfilled, (state, action) => {
                state.deleteItemCategoriesSuccess = true;
                state.deleteItemCategoriesLoading = false;
            })
            .addCase(deleteItemCategories.rejected, (state, action) => {
                const payload = action.payload.data;
                state.deleteItemCategoriesError = true;
                state.deleteItemCategoriesLoading = false;
                state.deleteItemCategoriesErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;
            })
    },
});

export const { resetSuccessFlag } = itemCategories.actions;
export const itemCategoriesSelector = (state) => state[namespace];
export default itemCategories.reducer;
